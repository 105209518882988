import React from 'react';

//Components
import Comments from '../Comments/index';
import Article from "../Article/index";
import { setDataInMatomo } from '../../Utils/matomo'

import './style.scss';
import '../../Utils/bxslider/jquery.bxslider.css';
import { validURL, createMarkup } from '../../Utils/ConvertFunctions';

class InnerPageFooter extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
    this.article_reference = this.props.article_reference.filter((item) => {
      return item?.id !== null;
    });
  }

  componentDidMount() {
    this.runTicker();
  }

  runTicker() {
    this.bxSlider && this.bxSlider.destroySlider();
    this.bxSlider = jQuery(this.myBxslider).bxSlider({
      slideMargin: 10,
      slideWidth: 280,
      adaptiveHeight: true,
      maxSlides: 3,
      pager: false
    });
  }

  render() {
    return (
      <div className="inner-page-footer">

        { !!this.props.attached_files && !!this.props.attached_files.length &&
          this.props.attached_files.find(e => Math.round(e.size / 1024) > 0) &&
          <div className="attachments">
            <span className="attachments-title"> קבצים מצורפים</span>
            { this.props.attached_files.map((item, key) => (
              Math.round(item.size / 1024) > 0 &&
              <div key={ key } className="row-file" >
                <div className={ item.type + "-file-icon file-icon" }></div>
                <div className="file-info">
                  <a
                    onClick={ () => {
                      setDataInMatomo(['trackEvent', 'לחיצה על PDF', this.props.main_title_inner_page]);
                    } }
                    href={ item.url }
                    target="_blank">
                    { item.name }
                  </a>
                  <span>{ Math.round(item.size / 1024) } ק"ב</span>
                </div>
              </div>
            )
            ) }
          </div>
        }

        { !!this.props.reference_links && !!this.props.reference_links.length &&
          <div className="reference_links">
            <div className="icon"></div>
            { this.props.reference_links.map((item, key) =>
              <a href={ `${!validURL(item.url) ? '/' : ''}${item.url}` } className="reference_link" key={ key }>{ item.title }</a>
            ) }
          </div>
        }

        { !!this.article_reference && !!this.article_reference.length &&
          <div className="more-articles">
            <div className="pane-title">כתבות נוספות בנושא</div>
            <div className="bxslider" ref={ el => this.myBxslider = el }>
              { this.article_reference.map((item, key) => (
                item &&
                <Article
                  key={ key }
                  { ...item }
                />
              )
              ) }
            </div>
          </div>
        }

        { this.props.comment_status != 0 && <Comments { ...this.props } /> }

        { this.props.hide_description != '1' && this.props.article_type == 'opinion' &&
          <div className="about-the-writer">
            <div className="writer-img">
              <a href={ "/search?writer=" + this.props.writer_id }>
                <img src={ this.props.writer_image } />
              </a>
            </div>
            <div className="writer-info">
              <div className="writer-name">
                <a href={ "/search?writer=" + this.props.writer_id }>{ this.props.writer_name }</a>
                <span> - תאור כתב</span>
              </div>
              <div className="writer-desc" dangerouslySetInnerHTML={ createMarkup(this.props.writer_description) }></div>
            </div>
          </div>
        }
        <hr />
        <div className="tags">
          { this.props.tags && this.props.tags.map((item, key) => (
            <a href={ `${location.origin}/search?tag=${item.id}` } className="tag" key={ key }>{ item.name }</a>
          )
          ) }
        </div>
      </div>
    );
  }
}

InnerPageFooter.propTypes = {
};

export default InnerPageFooter;
