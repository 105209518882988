import gql from "graphql-tag";
import { jsonToGraphQLQuery } from "json-to-graphql-query";

export default class Api {
  constructor() {
    this.listeners = [];
    this.searchObj = {};
    this.searchRang = {};
    this.searchParam = {};
    this.searchKey = "";
  }

  addListener(fun) {
    this.listeners.push(fun);
  }

  searchQuery() {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("key");

    if (token) {
      this.searchObj.keyword = token;
    } else {
      delete this.searchObj.keyword;
    }

    const query = {
      query: {
        elasticsearch: {
          __args: this.searchObj,
          searchEntity: true,
          total: true,
        },
      },
    };

    let graphql_query = jsonToGraphQLQuery(query, { pretty: true });
    graphql_query = gql`
      ${graphql_query}
    `;
    myApolloClient.query({ query: graphql_query }).then((result) => {
      this.listeners.forEach((listener) => {
        listener(result);
      });
    });
  }
}
