import React from "react";
import PropTypes from "prop-types";

//style
import "./style.scss";

class FreeSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
    };
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const key = urlParams.get("key");
    if (key) {
      this.setState({ value: key });
    }
  }

  handleChange(e) {
    this.setState({ value: e.target.value });
  }

  handleUserPressEnterKey = (event) => {
    // Check if the key pressed is Enter
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent the default action (e.g., form submission)
      this.handleSubmit();
    }
  };

  updateURLkey() {
    const newurl = new URL(window.location.href);
    const params = new URLSearchParams(newurl.search);

    if (this.state.value) {
      params.set("key", this.state.value);
    } else {
      params.delete("key");
    }
    newurl.search = params.toString();
    window.history.pushState({ path: newurl.href }, "", newurl.href);
  }

  handleSubmit(e) {
    this.updateURLkey();
    Api.searchQuery();
  }

  clearInput = () => {
    this.setState({ value: "" }, this.handleSubmit);
  };

  render() {
    return (
      <div className="free-search">
        <input
          className="search-value"
          placeholder="חיפוש"
          type="text"
          value={this.state.value}
          onChange={(e) => {
            this.handleChange(e);
          }}
          onKeyDown={(e) => {
            this.handleUserPressEnterKey(e);
          }}
        />
        {this.state.value && (
          <svg
            className="MuiSvgIcon-root MuiChip-deleteIcon clear-icon"
            onClick={this.clearInput}
            focusable="false"
            viewBox="0 0 24 24"
            aria-hidden="true"
            style={{
              color: "rgba(0, 0, 0,0.26)",
              borderRadius: "50%",
            }}
          >
            <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"></path>
          </svg>
        )}
        <input
          className="submit"
          type="submit"
          onClick={() => {
            this.handleSubmit();
          }}
        />
      </div>
    );
  }
}

FreeSearch.propTypes = {};

export default FreeSearch;
