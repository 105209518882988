import axios from 'axios';
import xml2js from 'xml2js';

async function fetchData() {
    const REACT_APP_MAP_PRODUCT_TYPE = Drupal.settings.CRComponentsSettings?.react_app_config.MAP_PRODUCT_TYPE;
    const REACT_APP_MAP_PRODUCT_ID = Drupal.settings.CRComponentsSettings?.react_app_config.MAP_PRODUCT_ID;
    const REACT_APP_API_KEY = Drupal.settings.CRComponentsSettings?.react_app_config.MAP_API_KEY;
    const REACT_APP_FIRST_DOMAIN = Drupal.settings.CRComponentsSettings?.react_app_config.MAP_FIRST_DOMAIN;
    const MAP_TOKEN = Drupal.settings.CRComponentsSettings?.react_app_config.MAP_TOKEN;
    const REACT_APP_SECOND_DOMAIN = Drupal.settings.CRComponentsSettings?.react_app_config.MAP_SECOND_DOMAIN;
    const REACT_APP_IS_INTERENAL = Drupal.settings.CRComponentsSettings?.react_app_config.IS_INTERENAL;

    let url = `${REACT_APP_FIRST_DOMAIN}`;
    if (REACT_APP_IS_INTERENAL) {
        url = `${REACT_APP_FIRST_DOMAIN}?token=${REACT_APP_API_KEY}`;
    }
    const headers = {
        'Content-Type': 'application/xml',
        'X-API-KEY': REACT_APP_API_KEY
    };

    const data = `<?xml version="1.0" encoding="UTF-8"?>
    <csw:GetRecords xmlns:csw="http://www.opengis.net/cat/csw/2.0.2" service="CSW" maxRecords="1" startPosition="1" outputSchema="http://schema.mapcolonies.com/raster" version="2.0.2" xmlns:mc="http://schema.mapcolonies.com/raster">
    <csw:Query typeNames="mc:MCRasterRecord">
      <csw:ElementSetName>full</csw:ElementSetName>
      <csw:Constraint version="1.1.0">
        <Filter xmlns="http://www.opengis.net/ogc">
          <And>
            <PropertyIsEqualTo>
              <PropertyName>mc:productType</PropertyName>
              <Literal>${REACT_APP_MAP_PRODUCT_TYPE}</Literal>
            </PropertyIsEqualTo>
            <PropertyIsEqualTo>
              <PropertyName>mc:productId</PropertyName>
              <Literal>${REACT_APP_MAP_PRODUCT_ID}</Literal>
            </PropertyIsEqualTo>
          </And>
        </Filter>
      </csw:Constraint>
    </csw:Query>
  </csw:GetRecords>`;

    try {
        let footprintGeoJSON = ""
        const response = await axios.post(url, data, { headers });
        if (response.status == 200 && response.data) {
            footprintGeoJSON = await extractFootprintFromResponse(response.data);
            let extent = await extractBBOXFromResponse(response.data);
            const { desired_layer_identifier, wmtsUrl } = extractWMTSInfoFromResponse(response.data, REACT_APP_SECOND_DOMAIN, REACT_APP_API_KEY, REACT_APP_IS_INTERENAL);

            if (wmtsUrl) {
                const secondResponse = await axios.get(wmtsUrl, {
                    headers: {
                        'Content-Type': 'application/xml',

                    }
                });

                // Inside your async function
                if (secondResponse.status === 200 && secondResponse.data) {
                    try {
                        // Use the helper function with await to parse the XML
                        const result = await parseXml(secondResponse.data);
                        const layers = result.Capabilities.Contents.Layer;
                        const layerInfo = layers
                            .filter(layer => layer['ows:Identifier'] === desired_layer_identifier)
                            .map(layer => ({
                                identifier: layer['ows:Identifier'],
                                url: layer.ResourceURL?.template,
                                tileMatrixSet: layer.TileMatrixSetLink?.TileMatrixSet,
                            }));


                        if (layerInfo[0]) {
                            let urlTemplate = layerInfo[0].url;

                            const urlPath = new URL(urlTemplate).pathname;
                            // Replace the domain
                            urlTemplate = `${REACT_APP_SECOND_DOMAIN}${urlPath}`
                            if (REACT_APP_IS_INTERENAL) {
                                urlTemplate = `${REACT_APP_SECOND_DOMAIN}${urlPath}?token=${REACT_APP_API_KEY}`;
                            }
                            //for internal env
                            // if (Drupal.settings.CRComponentsSettings?.react_app_config.MAP_URL_TEMPLATE) {
                            //     urlTemplate = Drupal.settings.CRComponentsSettings?.react_app_config.MAP_URL_TEMPLATE
                            // }
                            urlTemplate = decodeURIComponent(urlTemplate);

                            const tileMatrixSet = layerInfo[0].tileMatrixSet;
                            return { urlTemplate, footprintGeoJSON, desired_layer_identifier, tileMatrixSet, extent };
                        }
                    } catch (err) {
                        console.error('Error parsing XML:', err);
                    }
                }

            }
        }
    } catch (error) {
        console.error("Axios error: " + error.message);
    }
};

// Helper function to convert xml2js.parseString to use Promises
async function parseXml(xml) {
    return new Promise((resolve, reject) => {
        xml2js.parseString(xml, { mergeAttrs: true, explicitArray: false }, (err, result) => {
            if (err) {
                reject(err);
            } else {
                resolve(result);
            }
        });
    });
}
function extractBBOXFromResponse(xmlResponse) {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlResponse, "text/xml");
    const boundingBox = xmlDoc.getElementsByTagName("ows:BoundingBox")[0];
    const lowerCorner = boundingBox.getElementsByTagName("ows:LowerCorner")[0].textContent.split(" ");
    const upperCorner = boundingBox.getElementsByTagName("ows:UpperCorner")[0].textContent.split(" ");

    // Convert strings to floats
    const bounds = [
        parseFloat(lowerCorner[1]), parseFloat(lowerCorner[0]),
        parseFloat(upperCorner[1]), parseFloat(upperCorner[0])
    ];

    return bounds;
}

function extractWMTSInfoFromResponse(data, REACT_APP_SECOND_DOMAIN, REACT_APP_API_KEY, REACT_APP_IS_INTERENAL) {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(data, "text/xml");
    const links = xmlDoc.getElementsByTagName("mc:links");
    for (let i = 0; i < links.length; i++) {
        if (links[i].getAttribute("scheme") === "WMTS") {
            const desired_layer_identifier = links[i].getAttribute("name");
            let wmtsUrl = links[i].textContent.trim();
            const urlPath = new URL(wmtsUrl).pathname;
            // Replace the domain
            if (REACT_APP_IS_INTERENAL) {
                wmtsUrl = `${REACT_APP_SECOND_DOMAIN}${urlPath}?token=${REACT_APP_API_KEY}`;
            }
            wmtsUrl = `${REACT_APP_SECOND_DOMAIN}${urlPath}`;
            return { desired_layer_identifier, wmtsUrl }; // Return the extracted and modified values
        }
    }
    return {}; // Return an empty object if no WMTS info was found
};


// Function to parse XML and extract the footprint
async function extractFootprintFromResponse(xmlResponse) {
    const parser = new xml2js.Parser();
    const result = await parser.parseStringPromise(xmlResponse);
    const footprintString = result['csw:GetRecordsResponse']['csw:SearchResults'][0]['mc:MCRasterRecord'][0]['mc:footprint'][0];
    return JSON.parse(footprintString);
};


export {
    fetchData,
}

