import React from 'react';
import PropTypes from 'prop-types';
import gql from "graphql-tag";
import Button from '@material-ui/core/Button';

//style
import './style.scss';

//Componnets
import Article from "../Article/index";

class SectionLobbyArticles extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      articles: [],
      showLoadMore: true
    };

    this.loadMore = this.loadMore.bind(this);
    this.getData = this.getData.bind(this);

    this.getData()

  }
  getData(offset = 0) {
    const query = gql`
  {
    view(viewName:"section_lobby",displayId:"services_1",argument:"${this.props.section_id}",limit:20, offset: ${offset}){
      entity{
        id
        main_title_homepage
        main_title_inner_page
        created
        date_update
        article_type
        description_homepage
        video_homepage
        extension_video_homepage
        classification
        is_blue
        unit{
          name
          icon
        }
        image_src
        video_url
        image_video
        image_size
        path
      }
    }
  }
  `;

    myApolloClient.query({ query }).then(result => {
      let articles = (result.data.view.entity?.length) ? result.data.view.entity : [];
      articles = articles.filter((item) => {
        return (item.id !== null)
      })

      if(articles?.length < 20) {
        this.setState({showLoadMore: false})
      }
      this.setState({ articles: this.state.articles.concat(articles || []), offset });
    })
  }
  loadMore() {
    this.getData(this.state.offset + 20)
  }
  render() {
    return (
      !!this.state.articles.length &&
      <div className="section-lobby-articles">
        <div className="pane-title">{this.props.default_title ? this.props.tid_title : this.props.title}</div>
        {!!this.state.articles.length && this.state.articles.map((item, key) => (
          <Article
            key={key}
            {...item}
          />
        )
        )}
        {!!this.state.articles.length && this.state.showLoadMore && <Button className="load-more-btn" onClick={this.loadMore}>טען עוד</Button>}
      </div>
    );
  }
}

SectionLobbyArticles.propTypes = {

};

export default SectionLobbyArticles;
