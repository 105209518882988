import React from 'react';
import PropTypes from 'prop-types';
import gql from "graphql-tag";
import { getImageSrc } from '../../Utils/ConvertFunctions';

//Components
import Article from "../Article/index";

//Style
import './style.scss';

class HpArticlesSidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      articles: []
    };
    let query = '';
    if (props.show_articles_from_category && props.section_id)
      query = gql`
      {
        view(viewName:"section_lobby",displayId:"services_1",argument:"${props.section_id}",limit:20){
          entity{
            node_type
            main_title_homepage
            main_title_inner_page
            created
            date_update
            status
            article_type
            description_homepage
            video_homepage
            extension_video_homepage
            classification
            is_blue
            unit{
              name
              icon
            }
            image_src
            video_url
            image_video
            image_size
            path
          }
        }
      }
      `;

    else
      query = gql`
      {
        entity(ids:[${props.nodes_obj}]){
          node_type
          main_title_homepage
          main_title_inner_page
          created
          date_update
          status
          article_type
          description_homepage
          video_homepage
          extension_video_homepage
          classification
          is_blue
          unit{
            name
            icon
          }
          image_src
          video_url
          image_video
          image_size
          path
        }
      }
      `;

    myApolloClient.query({ query }).then(result => {
      let articles = result.data && (result.data.entity ? result.data.entity : result.data.view.entity);
      articles = articles.filter(item => item && item.status == '1');
      this.setState({ articles })
    });
  }

  render() {
    const { articles } = this.state;
    const maxArticlesToShow = 10; // Limit the number of articles to display

    return (
      !!articles && !!articles.length &&
      <div className="hp-articles-sidebar">
        <div className="pane-content">
          <div className="pane-title">
            {this.props.title}
            <a className="view-more" href={this.props.link}>{this.props.link_title}</a>
          </div>

          <div className="small-articles">
            {!!articles && articles.length && articles.slice(0, maxArticlesToShow).map((item, key) =>
              <Article
                { ...item }
                image_src={getImageSrc(item)}
                key={key}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

HpArticlesSidebar.propTypes = {
  articles: PropTypes.array,
  show_articles_from_category: PropTypes.bool,
  section_id: PropTypes.string,
  nodes_obj: PropTypes.string,
  title: PropTypes.string,
  link: PropTypes.string,
  link_title: PropTypes.string,
};

export default HpArticlesSidebar;
