import React from 'react'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import gql from 'graphql-tag'
import Comment from '../Comment'
import CommentForm from '../CommentForm'
import './style.scss'
let indent = 1
let commentIndent = []

class Comments extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      commentsNested: [],
      editId: -1,
      replyId: -1,
      open: false,
      mail: '',
      user: ''
    }
  }

  getMail() {
    const query = gql`
      {
        mail
      }
    `
    myApolloClient.query({ query }).then(result => {
      if (result.data.mail) this.setState({ mail: result.data.mail })
    })
  }

  getUser() {
    const query = gql`
      {
        user
      }
    `
    myApolloClient.query({ query }).then(result => {
      if (result.data.user) this.setState({ user: result.data.user })
    })
  }

  componentDidMount() {

    this.getMail()
    this.getUser()
    setTimeout(() => {
      this.scrollToHashElement();
    }, 1000);

  }
  scrollToHashElement() {
    var url = window.location.href;
    var index = url.indexOf("#");
    if (index !== -1) {
      var hash = url?.substring(index + 1);
      if (hash && hash.length > 1) {
        const elementId = hash;
        const targetElement = document.getElementById(elementId);
        if (targetElement) {
          const offset = targetElement.offsetTop;
          window.scrollTo({
            top: offset,
            behavior: 'smooth',
          });
        }
      }
    }
  }
  componentWillMount() {
    if (this.props.comments) {
      let commentsNested = this.getNestedChildren(this.props.comments, '0')
      this.printTree(commentsNested)
    }
  }

  getNestedChildren(arr, parent) {
    let children = []
    for (let i = 0; i < arr.length; ++i) {
      if (arr[i].pid == parent) {
        let grandChildren = this.getNestedChildren(arr, arr[i].cid)
        if (grandChildren.length) {
          arr[i].children = grandChildren
        }
        children.push(arr[i])
      }
    }
    return children
  }

  printTree(tree) {
    let _this = this
    tree.forEach(function (node) {
      node.indent = indent
      commentIndent.push(node)
      if (node.children) {
        indent++
        _this.printTree(node.children)
      }
      if (tree.indexOf(node) === tree.length - 1) {
        indent--
      }
    })
    this.setState({ commentsNested: commentIndent })
  }

  alertStatus(action) {
    confirmAlert({
      message: `תגובתך ${action} בהצלחה`,
      buttons: [{ label: 'OK' }]
    })
  }

  delete(comment) {
    let commentsNested = []
    let arr = this.state.commentsNested
    let pids = [comment.pid]
    arr.map((item, key) => {
      if (
        !pids.includes(item.pid) ||
        (item.pid == comment.pid && item.cid != comment.cid)
      )
        commentsNested.push(item)
      else pids.push(item.cid)
    })
    this.alertStatus('נמחקה')
    this.props.onComment(commentsNested.length)
    this.setState({ commentsNested })
  }

  updateComment(commentsNested, comment, key) {
    commentsNested[key].body = comment.body
    this.alertStatus('התעדכנה')
    return commentsNested
  }

  newComment(commentsNested, comment) {
    let key = 0
    let pids = [comment.pid]
    comment.indent = 1
    commentsNested.map((item, index) => {
      if (pids.includes(item.pid) || pids.includes(item.cid)) {
        key = index
        pids.push(item.cid)
        if (comment.pid == item.cid) comment.indent = item.indent + 1
      }
    })
    commentsNested.splice(key + 1, 0, comment)
    this.alertStatus('נוספה')
    return commentsNested
  }

  save(comment, key) {
    let commentsNested = this.state.commentsNested
    if (key != undefined)
      commentsNested = this.updateComment(commentsNested, comment, key)
    else commentsNested = this.newComment(commentsNested, comment)
    this.props.onComment(commentsNested.length)
    this.setState({ commentsNested, editId: -1, replyId: -1 })
    this.sendNotification({ pid: comment?.pid, name: comment?.name, cid: comment?.cid })
  }
  edit(key) {
    const editId = this.state.editId == -1 ? key : -1
    this.setState({ editId })
  }

  reply(key) {
    const replyId = this.state.replyId == -1 ? key : -1
    this.setState({ replyId })
  }

  sendNotification({ pid, name, cid }) {
    let query = gql`{
    sendNotification(
    pid:"${pid}", 
    name:"${name}", 
    cid:"${cid}",
    divId:"comment_${cid}"
     )}`
    myApolloClient.query({ query: query }).then(result => {
      console.info('sendNotification-result', result)
    })
  }
  renderCommentForm(cid, pid, key = undefined, body = undefined, displayName = undefined) {
    return (
      <CommentForm
        {...this.props}
        nid={this.props.id}
        pid={pid}
        cid={cid}
        body={body}
        displayName={displayName}
        onClose={() => {
          this.setState({ editId: -1, replyId: -1 })
        }}
        onSave={comment => {
          this.save(comment, key)
        }}
      />
    )
  }

  render() {

    return (
      <div className='comments'>
        <div className='header'>
          <strong>{this.state.commentsNested.length} </strong>
          <span>תגובות</span>
        </div>
        {!!this.state.commentsNested &&
          this.state.commentsNested.map((item, key) => {
            return (
              <div key={key} id={`comment_${item.cid}`}>
                <Comment
                  onEdit={() => {
                    this.edit(key)
                  }}
                  onReply={() => {
                    this.reply(key)
                  }}
                  onDelete={comment => {
                    this.delete(comment)
                  }}
                  nid={this.props.id}
                  displayName={this.item?.displayName}
                  showCommentFotter={item.mail == this.state.mail || item.name == this.state.user}
                  {...item}
                />
                {this.state.editId == key &&
                  this.renderCommentForm(item.cid, undefined, key, item.body, this.item?.displayName)}
                {this.state.replyId == key &&
                  this.renderCommentForm('0', item.cid)}
              </div>
            )
          })}

        {this.renderCommentForm('0', '0')}
      </div>
    )
  }
}

Comments.propTypes = {}

export default Comments
