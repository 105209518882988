import React from 'react';
import gql from "graphql-tag";
import uuid from 'uuid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DeleteIcon from '@material-ui/icons/Delete';

// internal dependecies
import AddFavorite from './AddFavorite';

//Style
import './style.scss';

class Favorites extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      favorites: [],
    }

    const query = gql`
    {
      getFavorites{
        id
        tid
        triangle
        triangleName
        name
        triangleClearance
        classification
        classificationId
      }
    }`;

    myApolloClient.query({ query }).then(result => {
      result.data.getFavorites && this.setState({ favorites: result.data.getFavorites });
    });
  }

  handleClick(item) {
    const mutate = gql`
    mutation{
      favorite(favorite:{
        vid: "${Drupal.settings.sources}"
        id: "${item.tid}"
        name: "${item.name}"
        classification: "${item.classification}"
        classificationId: "${item.classificationId}"
        triangleName: "${item.triangleName}"
        triangleClearance: "${item.triangleClearance}"
        triangle: "${item.triangle}"},
        method: "POST"
      )
    }`;

    myApolloClient.mutate({ mutation: mutate }).then(result => {
      if(result.data.favorite)
        this.setState({ favorites: [...this.state.favorites, item]});
    });
  }

  handleRemove(tid) {
    const mutate = gql`
      mutation {
        favorite(favorite:{tid:"${tid}"},
                 method:"DELETE")
      }`;

      myApolloClient.mutate({ mutation: mutate }).then(result => {
      if(result.data.favorite)
        this.setState({ favorites : this.state.favorites.length && this.state.favorites.filter(item => item.tid != tid) });
    });
  }

  render() {
    var favoritesList = this.state.favorites.length && this.state.favorites.map((item, key) => {
      return (
        <ListItem key = { key } >
          <ListItemIcon
            className = "remove"
            onClick = {() => {this.handleRemove(item.tid)}} >
            <DeleteIcon />
          </ListItemIcon>
          <ListItemText
            className = "item"
            primary= {item.name} />
        </ListItem>
      )
    });

    return (
      <div className="favorites">
          <div>
            <AddFavorite
              handleClick = {(item)=>{this.handleClick(item)}}
              favorites = {this.state.favorites}/>
          </div>
          <br />
          <List>
            <ul>{ favoritesList }</ul>
          </List>


      </div>
    );
  }
}

Favorites.propTypes = {
};

export default Favorites;
