import React from 'react';
import gql from "graphql-tag";

import './style.scss';

class ImagesList extends React.Component {

  constructor(props) {
      super(props);
      this.state = {
       data:[]
      };

      const query = gql`
      {
        entity(ids:[${props.nodes_obj}]){
        id
        status
        main_title_inner_page
        more_url
        more_title
        images_list{
          image_url
          text
          link
         }
        }
      }
    `;
      myApolloClient.query({ query }).then(result => {
        let data = result.data.entity.length && result.data.entity.filter(q => q && q?.status == '1');
        data = data.length && data[0];
        this.setState({data});
      });
  }

  render() {
    const {data} =this.state;
    return (
      !!data &&
      <div className="images-list">
        <div className="pane-content">
          <div className="pane-title">{data.main_title_inner_page}
             <a className="view-more" href={data.more_url}>{data.more_title}</a>
          </div>
          <div>
          {!!data.images_list && !!data.images_list.length && data.images_list.map((item, key) =>
          <div className="gallery" key={key}>
          <div>
            <a target="_blank" href={item.link}>
              <img src={item.image_url} alt={item.text}/>
            </a>
          </div>
            <div className="img-title">{item.text}</div>
          </div>
          )}
            </div>
        </div>
      </div>
    );
  }
}

ImagesList.propTypes = {
};

export default ImagesList;
