import React from 'react';
import PropTypes from 'prop-types';
import gql from "graphql-tag";

import Article from "components/Article";
import './style.scss';

class HpArticlesList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      articles: []
    };
    const query = gql`
    {
      entity(ids:[${props.nodes_obj}]){
        main_title_homepage
        main_title_inner_page
        status
        created
        date_update
        article_type
        description_homepage
        video_homepage
        extension_video_homepage
        classification
        is_blue
        unit{
          name
          icon
        }
        image_src
        video_url
        image_video
        image_size
        path
      }
    }
    `;

    myApolloClient.query({ query }).then(result => {
      let articles = result.data.entity;
      articles = articles.filter(item => item && item.status == '1');
      this.setState({articles})
    });
  }

  render() {
    if (this.state.articles.length === 0) {
      return null;
    }
    return (
      <div className="hp-articles-list">
      <div className="pane-title">{this.props.title}
        <a className="view-more" href={this.props.link}>{this.props.link_title}</a>
      </div>
      {!!this.state.articles.length && this.state.articles.map((item, key) =>
          <Article
            key={key}
            { ...item}
          />
        )}

      </div>
    );
  }
}

HpArticlesList.propTypes = {

};

export default HpArticlesList;
