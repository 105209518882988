import React from 'react';
import PropTypes from 'prop-types';
import gql from "graphql-tag";
// import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
// import 'pure-react-carousel/dist/react-carousel.es.css';
// import "react-responsive-carousel/lib/styles/carousel.min.css";
// import { Carousel } from 'react-responsive-carousel';
import './style.scss';
import '../../Utils/bxslider/jquery.bxslider.css'

//Componnets
import Article from "../Article/index";

class HpImageNodequeue extends React.Component {

  constructor(props) {

    super(props);
    this.state = {
      articles: []
    };
    const query = gql`
    {
      view(viewName:"hp_image",displayId:"services_2",argument:"${props.subqueue}"){
        entity{
          id
          main_title_inner_page
          description_hp_image
          image_src_hp_image
          unit{
            name
            icon
          }
          classification
          created
          video_url
          image_video
          image_link
          image_size
          path
          article_type
        }
      }
    }
    `;
    myApolloClient.query({ query }).then(result => {
      this.setState({articles: result.data.view.entity }, this.runTicker)
    })

  }

  runTicker(){
    this.bxSlider && this.bxSlider.destroySlider();

    this.bxSlider = jQuery(this.myBxslider).bxSlider({
            captions: true,
            slideWidth: 996,
            adaptiveHeight:true
      });
  }

  render() {
    return (
      !!this.state.articles && !!this.state.articles.length &&
      <div className="hp-image">
          <div className="pane-title">{this.props.title}
            <a className="view-more" href={this.props.link}>View More</a>
          </div>
        {!!this.state.articles && !!this.state.articles.length && <div className="bxslider" ref={el => this.myBxslider = el}>
          {this.state.articles.map((item, key)=> (
            item && item.id &&
            <Article
              key={key}
              {...item}
              description_homepage ={item && item.description_hp_image}
              image_src ={item && item.image_src_hp_image}
            />
              )
          )}
        </div>
        }

      </div>
    );
  }
}

HpImageNodequeue.propTypes = {

};

export default HpImageNodequeue;
