import React from 'react'
import gql from 'graphql-tag'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'

import './style.scss'
import moment from 'moment'
import CommentForm from '../CommentForm'

moment.locale('he')
const margin = 18

class Comments extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      openReply: false,
      openEdit: false,
      isEditing: false,
      editedName: this.props.displayName || this.props.name,

    }
  }

  delete(comment) {
    const mutate = gql`
     mutation{
      comment(comment:{cid: "${comment.cid}"}, method: "DELETE"){
        cid
      }
       }  
     `

    myApolloClient.mutate({ mutation: mutate }).then(result => {
      result.data.comment.cid && this.props.onDelete(comment)
    })
  }

  submit() {
    let text = this.props?.body?.substring(0, 70) || ''

    if (this.props?.body?.length >= 70) {
      text += '...'
    }
    confirmAlert({
      message: (
        <div className='text-comment'>
          <div>{`רק מוודאים כי ברצונך למחוק את התגובה: "${text}" ?`}</div>
        </div>
      ),
      buttons: [
        {
          label: 'כן',
          onClick: () => this.delete(this.props)
        },
        {
          label: 'לא'
        }
      ]
    })
  }

  handleEditStart = () => {
    this.setState({ isEditing: true });
  };

  handleNameChange = (event) => {
    this.setState({ editedName: event.target.value });
  };

  handleSave = () => {
    const { cid } = this.props;
    const { editedName } = this.state;

    const mutate = gql`
    mutation {
      updateCommentDisplayName(cid: "${cid}", displayName: "${editedName}",nid: "${this.props.nid}")
    }
    `;
    myApolloClient.mutate({ mutation: mutate }).then(result => {
      this.setState({ isEditing: false });
    });


  };

  DisplayName = () => {
    const { isEditing, editedName } = this.state;

    return (
      <div className='name'>
        {isEditing && this.props.showCommentFotter === true ? (
          <input type="text" value={editedName} onChange={this.handleNameChange} />
        ) : (
          <>{editedName}</>
        )}
        {this.props.showCommentFotter === true ? <div role="button" onClick={isEditing ? this.handleSave : this.handleEditStart}>
          {isEditing ? <p>שמור</p> : <div className="edit-display-name" />}
        </div> : ""}
      </div>
    );
  };

  render() {
    return (
      <div
        className='comment'
        style={{ marginRight: this.props.indent * margin + 'px' }}
      >
        {this.DisplayName()}
        <span className='body'> {this.props.body}</span>
        <div className='footer'>
          <span className='created'>
            {!!this.props.created &&
              moment(this.props.created * 1000).format('DD.MM.YYYY') +
              ' | ' +
              moment(this.props.created * 1000).format('LT')}
          </span>
          <div className='actions'>

            {this.props.showCommentFotter === true && (
              <>
                <div
                  className='action delete'
                  onClick={() => {
                    this.submit()
                  }}
                >
                  מחק
                </div>
                <div
                  className='action edit'
                  onClick={() => {
                    this.props.onEdit()
                  }}
                >
                  ערוך
                </div>
              </>)}
            <div
              className='action reply'
              onClick={() => {
                this.props.onReply()
              }}
            >
              הגב
            </div>
          </div>

        </div>
      </div>
    )
  }
}

Comments.propTypes = {}

export default Comments
