import React from "react";
import IronImage from "../IronImage";
import defaultImage from "../../assets/img/placeholder.svg";
import ReactPlayer from "react-player";
import GifPlayer from "react-gif-player";
import { createMarkup } from "../../Utils/ConvertFunctions";
import {
  nodeTypeOptions,
  articleTypeOptions,
} from "../../Utils/ConvertFunctions";

//Style
import "./style.scss";
import moment from "moment";
moment.locale("he");

class Article extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      playing: false,
      image_src:
        this.props.article_type == "video"
          ? this.props.image_video
          : this.props.image_src,
    };
  }

  componentWillReceiveProps(props) {
    //  if(!this.state.image_src)
    this.setState({
      image_src:
        props.article_type == "video" ? props.image_video : props.image_src,
    });
  }

  handleImageErrored() {
    this.setState({ image_src: defaultImage });
  }

  handleVideoOver() {
    this.setState({ playing: !this.state.playing });
  }

  handleGifOver() {
    this.gifPlayer.toggle();
  }

  renderBlockVideo() {
    let blockVideo = [];
    let renderBlock = [];
    // If the page is home page and the video_homepage field is set.
    if (
      (location.href == location.origin + "/" ||
        location.href == location.origin + "/home_en") &&
      this.props.video_homepage
    ) {
      // push gif image.
      if (this.props.extension_video_homepage == "gif")
        blockVideo.push(
          <GifPlayer
            key="2"
            ref={(el) => (this.gifPlayer = el)}
            gif={this.props.video_homepage}
            onMouseOver={this.handleGifOver.bind(this)}
            onMouseOut={this.handleGifOver.bind(this)}
          />
        );
      // push mp4 video.
      else
        blockVideo.push(
          <ReactPlayer
            key="3"
            url={this.props.video_homepage}
            playing={this.state.playing}
            muted={true}
            className="video-playing"
            onMouseOver={this.handleVideoOver.bind(this)}
            onMouseOut={this.handleVideoOver.bind(this)}
          />
        );
    }
    // If the page is not home page or the video_homepage field is not set.
    // push default image.
    else {
      blockVideo.push(
        <img
          key="4"
          src={this.state.image_src || this.props.image_video || defaultImage}
          onError={this.handleImageErrored.bind(this)}
        />
      );
    }
    renderBlock.push(
      <div key="0" className="video">
        <div key="1" className="vid-icon"></div>
        {blockVideo}
      </div>
    );
    return renderBlock;
  }

  render() {
    return (
      <div className={`article ${this.props.is_blue === "1" ? "is-blue" : ""}`}>
        <a
          href={
            this.props.node_type == "newsflash"
              ? `newsflash#${this.props.id}`
              : this.props.image_link
              ? this.props.image_link
              : this.props.path
          }
        >
          <div className="img" ref={(el) => (this.myImg = el)}>
            {this.props.article_type && this.props.article_type == "video" ? (
              this.renderBlockVideo()
            ) : (
              <img
                src={
                  this.state.image_src || this.props.image_src || defaultImage
                }
                onError={this.handleImageErrored.bind(this)}
              />
            )}
            {/* {<div className="video"><div className="vid-icon"></div><IronImage srcPreload={image} srcLoaded={this.props.image_video} /></div>
            : <IronImage srcPreload={image} srcLoaded={this.props.image_src} />} */}
          </div>
          <div className="content">
            {this.props.main_article_type == "main_articles_six" && (
              <p className="red_title">{this.props.red_title}</p>
            )}
            <p className="title">
              {this.props.main_title_homepage ||
                this.props.main_title_inner_page}
            </p>
            {/* <div className="content" dangerouslySetInnerHTML={createMarkup(this.props.content)}></div> */}
            <p
              className="description"
              dangerouslySetInnerHTML={createMarkup(
                this.props.description_homepage || this.props.newsflash_content
              )}
            ></p>
            <div className="article-info">
              {this.props.node_type && (
                <>
                  סוג תוכן:{" "}
                  <span className="unit">
                    {this.props.node_type === "article"
                      ? articleTypeOptions[this.props.article_type]
                      : nodeTypeOptions[this.props.node_type] || ""}
                  </span>
                </>
              )}
              יחידה:{" "}
              <span className="unit">
                {!!this.props.unit &&
                this.props.unit.length &&
                this.props.unit[0].name
                  ? this.props.unit[0].name
                  : this.props.unit}
              </span>
              סיווג:{" "}
              <span className="ttl">
                {this.props.is_blue === "1"
                  ? "כחול"
                  : this.props.classification
                  ? this.props.classification
                  : "לא מסווג"}{" "}
              </span>
              <span className="created">
                {this.props.date_update
                  ? moment(this.props.date_update * 1000).format("LT")
                  : moment(this.props.created * 1000).format("LT")}
              </span>
              <span className="created">
                {this.props.date_update
                  ? moment(this.props.date_update * 1000).format("DD.MM.YYYY")
                  : moment(this.props.created * 1000).format("DD.MM.YYYY")}
              </span>
            </div>
          </div>
        </a>
      </div>
    );
  }
}

Article.propTypes = {};

export default Article;
