import React from 'react';
import gql from "graphql-tag";
import PropTypes from 'prop-types';


//Style
import './style.scss';
import { validURL } from '../../Utils/ConvertFunctions';

class Menu extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      links: []
    };
    const query = gql`
    {
      menu(menuName:"${props.menu_name}"){
        title
        href
      }
    }
   `;


    myApolloClient.query({ query }).then(result => {
      this.setState({ links: result.data.menu });
    });
    //replace the href of the second link element with the path of the daily
    myApolloClient.query({ query: gql`{daliyLink}` }).then(result => {
      if (result?.data?.daliyLink?.path) {
        jQuery('#daily').attr('href', result?.data?.daliyLink?.path);
        jQuery('#daily').css('display', 'block');
      }
    });

    myApolloClient.query({ query: gql`{bureauUpdateLink}` }).then(result => {
      if (result?.data?.bureauUpdateLink?.path) {
        jQuery('#bureau-update').attr('href', result.data.bureauUpdateLink.path);
        jQuery('#bureau-update').css('display', 'block');
      }
    });
  }

  render() {
    return (
      <div className="menu">
        {!!this.state?.links?.length && this.state?.links.map((item, key) =>
          item &&
          <a key={key} href={`${!validURL(item.href) ? '/' : ''}${item.href}`}>{item.title}</a>
        )}
      </div>
    );
  }
}

Menu.propTypes = {

};

export default Menu;
