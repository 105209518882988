import React from 'react';

//Utils
import {createMarkup} from '../../Utils/ConvertFunctions';

//Style
import './style.scss';

class CustomPane extends React.Component {

  constructor(props) {
    super(props);
    this.state = {      
    }
   }


  render() {
    return (
      <div className="custom-pane">
        {this.props.admin_links &&
          <div className = "configure-content">
            <a href = {`/${this.props.admin_links[0].href}`}>ערוך תוכן</a>
          </div>    
        }    
        <div className="pane-title">{this.props.title}</div>
        <div className="content" dangerouslySetInnerHTML={createMarkup(this.props.content)}></div>
      </div>
    );
  }
}

CustomPane.propTypes = {

};

export default CustomPane;
