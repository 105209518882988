import React, { Component, PropTypes } from 'react';
import Button from '@material-ui/core/Button';
import AsyncSelect from 'react-select/async';
import gql from "graphql-tag";

class AddFavorite extends Component {
	
	constructor(props) {
		super(props);
		this.onClick = this.onClick.bind(this);
		this.onChange = this.onChange.bind(this);
		this.state = {
			itemToAdd: '',
			favorites: [],
			option_true: false,
		}
		this.promiseOptions = this.promiseOptions.bind(this);
	}

	componentWillMount() {
		this.setState({favorites: this.props.favorites.length && this.props.favorites.map(q => q.id)});
	}

	componentWillReceiveProps(nextProps) {
		nextProps.favorites != this.props.favorites &&
		this.setState({favorites: nextProps.favorites.length && nextProps.favorites.map(q => q.id)});
	}


	onClick() {
		if(this.state.itemToAdd == '') 
			return;
		else {
			var form = document.getElementById("myForm");
			form.reset();
			this.props.handleClick(this.state.itemToAdd);
			this.state.itemToAdd = '';
		}
	}

	onChange(e) {
		this.setState({ itemToAdd: e });
	}

	promiseOptions( inputValue ) {
		return new Promise(resolve => {
		  const query = gql`
		  {
			aclGroups(urlApi:"${Drupal.settings.api_url_1}",apiQueryString:"${inputValue}") {
				id
				name
			  classification
			  classificationId
			  triangleName
			  triangleClearance
			  triangle
			}
			}`;
		  myApolloClient.query({ query }).then(result => {
				let aclGroups = result.data.aclGroups.map(q => (Object.assign(q, {value: q.name, label: q.name})));
				aclGroups = aclGroups.filter(q => !this.state.favorites.length || !this.state.favorites.includes(q.id));
				return resolve(aclGroups);
				}).catch(err => {
					console.log(err);
				})
		});
	  }



	

	render() {
		return(
			<div>
				<form id="myForm">
					<AsyncSelect 
						className="select-item"
						value = {{label: this.state.itemToAdd.value, value:this.state.itemToAdd.value}}
						loadOptions={(e) =>this.state.option_true ? this.promiseOptions(e): []} 
						onChange = {(e) => {this.onChange(e)}}
						onInputChange={(e) => {this.setState({option_true: e.length >= 3})}} 
						multi={true}
					/>
					<Button 
						variant="contained" 
						onClick = {() => {this.onClick()}} 
						className = "add-button">
						הוסף
					</Button>
				</form>				
			</div>
		)
	}
}

AddFavorite.propTypes = { 
}

export default AddFavorite;