import React from 'react';
import gql from "graphql-tag";

//Componnets
import InnerPageHeader from '../InnerPageHeader/index';
import InnerPageFooter from '../InnerPageFooter/index';
import Article from '../Article';
import { goTo403 } from '../../Utils/ConvertFunctions';
import MetaTags from 'react-meta-tags';
import { setDataInMatomo, setTitleAndUrlInMatomo } from '../../Utils/matomo'
import Gallery from '../Gallery'
//Style
import './style.scss';

//Vars
let videoStartTime = false;
let videoWatchedTime = 0;
class AritclePage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      playVideo: false,
      articleData: -1,
      queryProps: [],
      comment_count: '',
    };

    const query = gql`
    {
      entity(ids:[{id:${props.nid},style:"${props.build_mode}"}]){
        main_title_inner_page
        id
        description_inner_page
        writer_id
        writer_name
        writer_image
        writer_description
        confirmed
        tested
        compatible_with
        vote_count
        hide_description
        hide_image
        reference
        video_text
        reference_links{
          title
          url
        }
        reading_time
        created
        date_update
        confirmed
        image_src
        image_desc
        galery_images {
          src
          desc
        }
        video_url
        image_video
        quote
        quote_source
        body
        article_type
        article_reference {
          id
          main_title_homepage
          image_src
          path
          article_type
          image_video
        }
        tags {
          id
          name
        }
        unit{
          name
          icon
        }
        attached_files{
          name
          url
          type
          size
        }
        classification
        is_blue
        triangle
        triangleName
        comment_status
        comments{
          cid
          pid
          name
          subject
          body
          mail
          created
          displayName
        }
        publishProcedureAllow
        gallery_nid
      }
    }
   `;

    myApolloClient.query({ query }).then(result => {
      let comment_count = result.data.entity.length && result.data.entity[0] && result.data.entity[0].comments ? result.data.entity[0].comments.length : 0;

      this.setState({ articleData: result.data.entity[0], comment_count }, this.runTicker);
      // Set classification & writer name in matomo.
      // this.state.articleData.classification && setDataInMatomo(['trackEvent', 'סיווג', this.state.articleData.classification]);
      // this.state.articleData.writer_name && setDataInMatomo(['trackEvent', 'זירה', this.state.articleData.writer_name]);
    });
  }

  videoOnPlay(event) {
    videoStartTime = event.timeStamp;
    this.setState({ playVideo: true })
  }

  videoOnPause(event) {
    const time = Math.floor(event.timeStamp - videoStartTime);
    videoStartTime = 0;
    videoWatchedTime += time;
    this.setState({ playVideo: false });
  }

  videoAddEvent(body) {
    let allVideo = body.querySelectorAll('video');
    Object.values(allVideo).map((video, index) => {
      video.addEventListener('play', (event) => this.videoOnPlay(event))
      video.addEventListener('pause', (event) => this.videoOnPause(event))
    })
  }

  runTicker() {
    this.bxSlider && this.bxSlider.destroySlider();
    this.bxSlider = jQuery(this.myBxslider).bxSlider({
      slideWidth: 860,
      adaptiveHeight: true,
      maxSlides: 1,
      pager: false
    });
  }

  createMarkup() {
    let body = this.state.articleData.body;
    const quote_source = (this?.state?.articleData?.quote_source) ? this?.state?.articleData?.quote_source : '';
    const quoteBody = (this?.state?.articleData?.quote) ? this?.state?.articleData?.quote : '';
    let quote = (quoteBody) ? '<div class="quote-wrapper"><div class="quote-icon"></div><div class="border"></div><div class="quote">' + quoteBody + '<div class="quote-source">' + quote_source + '</div></div></div>' : '';
    body = body.replace('[quote]', quote);
    let htmlObject = document.createElement('div');
    htmlObject.innerHTML = body;
    //adding image long description
    let images = htmlObject.querySelectorAll('img');
    for (let i = 0; i < images.length; i++) {
      if (images[i].getAttribute('longdesc')) {
        let el = images[i];
        let wrapper = document.createElement('div');
        wrapper.className = 'wrap-img';
        el.parentNode.insertBefore(wrapper, el);
        wrapper.appendChild(el);
        let desc = document.createElement('div');
        desc.className = 'description';
        desc.innerHTML = images[i].getAttribute('longdesc');
        images[i].parentNode.insertBefore(desc, images[i].nextSibling)
      }
    }

    return { __html: htmlObject.innerHTML };
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', (event) => this.handleTabClose(event));
  }

  handleTabClose(event) {
    event.preventDefault();

    // Set whatched video time.
    if (videoWatchedTime || videoStartTime) {
      if (videoStartTime !== 0) {
        this.videoOnPause(event);
      }
      const minutes = `0${Math.floor(videoWatchedTime / 1000 / 60)}`.slice(-2);
      const sec = `0${Math.floor(videoWatchedTime / 1000 % 60)}`.slice(-2);
      setDataInMatomo(['trackEvent', 'זמן צפייה בוידאו', this.state.articleData.main_title_inner_page, `${minutes}:${sec}`]);
    }

    return;
  };


  componentDidMount() {
    window.addEventListener('beforeunload', (event) => this.handleTabClose(event));

    setTimeout(function () {
      let body = document.querySelector('.article-page .body');
      if (body) {
        // moving this code to createMarkup() in order to solve refresh problem
        // let images = body.querySelectorAll('img');
        // for (let i = 0; i < images.length; i++) {
        //   if (images[i].getAttribute('longdesc')) {
        //     let el = images[i];
        //     let wrapper = document.createElement('div');
        //     wrapper.className = 'wrap-img';
        //     el.parentNode.insertBefore(wrapper, el);
        //     wrapper.appendChild(el);
        //     let desc = document.createElement('div');
        //     desc.className = 'description';
        //     desc.innerHTML = images[i].getAttribute('longdesc');
        //     images[i].parentNode.insertBefore(desc, images[i].nextSibling)
        //   }
        // }
        this.videoAddEvent(body);
      }
    }
      .bind(this),
      1000);
  }

  render() {
    return (
      <div
        className={ `article-page ${this.state.articleData && this.state.articleData.is_blue === "1" ? 'is-blue' : ''}` }
      >
        {
          this.state.articleData != -1 &&
          (
            this.state.articleData && this.state.articleData.id ?
              <div>
                < MetaTags >
                  <title>
                    { this.state.articleData.main_title_inner_page }
                  </title>
                  <meta
                    id="og-title"
                    property="og:title"
                    content={ this.state.articleData.main_title_inner_page }
                  />
                </MetaTags >
                {
                  setTitleAndUrlInMatomo(this.state.articleData.main_title_inner_page)
                }

                <InnerPageHeader
                  { ...this.state.articleData }
                  comment_count={ this.state.comment_count }
                />

                <div
                  className="content-body"
                >
                  {
                    this.state.articleData?.gallery_nid ?
                        (<Gallery nid={this.state.articleData?.gallery_nid}  />)

                    :
                    !!this.state.articleData.hide_image &&
                    this.state.articleData.hide_image != '1' &&
                    (
                      <div>
                        {
                          this.state.articleData.article_type && this.state.articleData.article_type == 'video' ?
                            <div
                              className="wrap-video"
                            >
                              <video
                                onPlay={ (e) => this.videoOnPlay(e) }
                                onPause={ (e) => this.videoOnPause(e) }
                                className={ this.state.playVideo ? 'video-play' : 'video-stop' }
                                width="400"
                                controls
                                poster={ this.state.articleData.image_video }
                              >
                                <source
                                  src={ this.state.articleData.video_url }
                                  type="video/mp4"
                                />
                              </video>
                              {
                                this.state.articleData.video_text && this.state.articleData.video_text !== '' &&
                                (
                                  <p
                                    className="description"
                                  >
                                    { this.state.articleData.video_text }
                                  </p>
                                )
                              }
                            </div>
                            :
                            !!this.state.articleData.galery_images && !!this.state.articleData.galery_images.length &&
                            <div
                              className="bxslider"
                              ref={ el => this.myBxslider = el }
                            >
                              {
                                this.state.articleData.galery_images.map((item, key) => (
                                  <Article
                                    key={ key }
                                    image_src={ item.src }
                                    description_homepage={ item.desc }
                                    { ...item }
                                  />
                                ))
                              }
                            </div>
                        }
                      </div>
                    )
                  }

                  {
                    !!this.state.articleData.body &&
                    <div
                      className="body"
                    >
                      <span>
                        <div
                          dangerouslySetInnerHTML={ this.createMarkup() }
                        >
                        </div>
                      </span>
                    </div>
                  }
                </div>
                <InnerPageFooter
                  { ...this.state.articleData }
                  onComment={ (comment_count) => { this.setState({ comment_count }); } }
                />
              </div>
              :goTo403(this.state.articleData)
            )
        }
      </div>
    );
  }
}

export default AritclePage;
