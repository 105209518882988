import React from 'react';
import PropTypes from 'prop-types';
import gql from "graphql-tag";
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';

//Style
import './style.scss';

class Compartmentalization extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      includeNewsLinkField: jQuery('.page-node-edit.node-type-maoz-newsflash').length || jQuery('.page-node-add-maoz-newsflash').length ? true : false,
      aclGroups: [],
      name: [],
      other: false,
      api_url_1: Drupal.settings.api_url_1,
      api_url_2: Drupal.settings.api_url_2,
      api_result1: [],
      api_result2: [],
      option_true: false,
      groups: {
        source: [],
        Classification: [],
        classificationId: [],
        TrianglesAllow: { TriangleId: '', TriangleClearance: '', TriangleName: '' },
        PublishProcedureAllow: [],
        newsLinkField: {
          value: '',
          error: false
        }
      }
    };

    this.getApiQuery = this.getApiQuery.bind(this);
    this.promiseOptions = this.promiseOptions.bind(this);
    this.onBlurNewsLinkField = this.onBlurNewsLinkField.bind(this);
    window.addEventListener('message', function (event) {
      if (event.data.message === undefined) {
        return;
      }
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = event.data.message;
      const aTag = tempDiv.querySelector('a');
      console.info(aTag, "link")
      if (aTag) {
        const aHref = aTag.getAttribute('href');
        // Call the onBlurNewsLinkField method from the constructor
        this.onBlurNewsLinkField(aHref);
      }
    }.bind(this));
  }



  getApiQuery(api_results, api_url) {
    const query = gql`
  {
    aclGroups(urlApi:"${api_url}", apiQueryString:"") {
      id
      name
      classificationId
      triangleName
      classification
      triangle
    }  
  }`;

    myApolloClient.query({ query }).then(result => {
      const apiResult = result.data.aclGroups.map(q => ({ value: q.name, label: q.name, key: q.id }));
      if (api_results == 'api_result2') {
        apiResult.unshift({ value: '', label: 'ללא', key: '0' });
      }
      this.setState({ [api_results]: apiResult });
    }).catch(err => {
      console.log('err', err)
    })
  }

  componentDidMount() {
    this.setSelected()
    this.getApiQuery('api_result1', this.state.api_url_1);
    this.getApiQuery('api_result2', this.state.api_url_2);
  }

  setSelected() {
    let selectedValues = '';
    jQuery('#edit-menu-fields-enabled').prop("checked", true);

    // If the component for the lowest compartmentalization
    this.props.low_compartmentalization ?
      selectedValues = document.getElementsByName('classification_flat_comp')[0].value :
      // If the component for the content compartmentalization
      selectedValues = jQuery('#edit-field-compartmentalization input').val();

    selectedValues && this.setState({ groups: JSON.parse(selectedValues) });
  }

  handlAclChangeSource(value) {
    let groups = this.state.groups;
    if (value.value == 'other') {
      let other = !this.state.other;
      this.setState({ other });
    }
    else {
      groups['source'] = { label: value.label, value: value.value };
      groups['Classification'] = value.classification;
      groups['classificationId'] = value.classificationId;
      groups['TrianglesAllow'] = { TriangleId: value.triangle, TriangleClearance: value.triangleClearance, TriangleName: value.triangleName }

      jQuery('#edit-field-compartmentalization input').val(JSON.stringify(groups));
    }

    // If the component for the lowest compartmentalization
    if (this.props.low_compartmentalization) {
      document.getElementsByName('triangleId_flat_comp')[0].value = value.triangle;
      document.getElementsByName('triangleclearance_flat_com')[0].value = value.triangleClearance;
      document.getElementsByName('classification_flat_comp')[0].value = JSON.stringify(groups);
    }

    // If the component for the content compartmentalization
    else {
      jQuery('#edit-field-flat-comp-und-0-field-classification-flat-comp-und-0-value').val(value.classificationId);
      jQuery('#edit-field-flat-comp-und-0-field-triangleid-flat-comp-und-0-value').val(value.triangle);
      jQuery('#edit-field-flat-comp-und-0-field-triangleclearance-flat-com-und-0-value').val(value.triangleClearance);
    }
  }

  handlAclChange(value) {
    let groups = this.state.groups;
    groups['PublishProcedureAllow'] = { label: value.label, value: value.value, key: value.key };

    // If the component for the lowest compartmentalization
    if (this.props.low_compartmentalization) {
      document.getElementsByName('classification_flat_comp')[0].value = JSON.stringify(groups);
      document.getElementsByName('publishprocedure_flat_comp')[0].value = value.key ? value.key : 0;
    }

    // If the component for the content compartmentalization
    else {
      if (value.key)
        jQuery('#edit-field-flat-comp-und-0-field-publishprocedure-flat-comp-und-0-value').val(value.key);
      else
        jQuery('#edit-field-flat-comp-und-0-field-publishprocedure-flat-comp-und-0-value').val("0");
      jQuery('#edit-field-compartmentalization input').val(JSON.stringify(groups));
    }
    this.setState({ groups });
  }

  promiseOptions(inputValue) {
    return new Promise(resolve => {
      let query;
      if (this.state.other)
        query = gql`
        {
          aclGroups(urlApi:"${this.state.api_url_1}",apiQueryString:"${inputValue}") {
            name
            classification
            classificationId
            triangleName
            triangleClearance
            triangle
          }
        }`;
      else
        query = gql`
        {
          getFavorites(queryStr: "${inputValue}") {
            tid
            name
            classification
            classificationId
            triangleName
            triangleClearance
            triangle
          }
        }`;
      myApolloClient.query({ query }).then(result => {
        let options = result.data.getFavorites ? result.data.getFavorites : result.data.aclGroups;
        options = options.map(q => (Object.assign(q, { value: q.name, label: q.name })));
        options.push({ value: 'other', label: 'אחר' });
        return resolve(options);
      }).catch(err => {
        console.log('err', err)
      })
    });
  }

  getIntellitemValues(newsLink) {
    const query = gql`
  {
    getIntellitemsData(newsLink: "${newsLink}")  
  }`;

    myApolloClient.query({ query, fetchPolicy: "no-cache" }).then(async result => {
      const intellitemValues = await result.data.getIntellitemsData?.[0]?.bamam;

      if (!intellitemValues) {
        let groups = this.state.groups;

        groups.newsLinkField = {
          value: newsLink,
          error: true
        };

        jQuery('#edit-field-compartmentalization input').val(JSON.stringify(groups));
        this.setState({ groups });
        return;
      }

      const groups = {
        source: {
          label: intellitemValues.slName,
          value: intellitemValues.slName
        },
        Classification: intellitemValues.classificationName,
        classificationId: intellitemValues.classification,
        TrianglesAllow: {
          TriangleId: intellitemValues.triangle,
          TriangleClearance: intellitemValues.cl,
          TriangleName: intellitemValues.triangleName
        },
        PublishProcedureAllow: {
          label: intellitemValues.procedureName,
          value: intellitemValues.procedureName,
          key: intellitemValues.procedure
        },
        newsLinkField: {
          value: newsLink,
          error: false
        }
      }

      jQuery('#edit-field-compartmentalization input').val(JSON.stringify(groups));
      this.setState({ groups });

    }).catch(err => {
      console.log('Error in fetching newsLink values: ', err)
    })
  }

  onBlurNewsLinkField(value) {
    if (value == this.state.groups?.newsLinkField?.value)
      return;

    if (!value) {
      const groups = {
        source: [],
        Classification: [],
        classificationId: [],
        TrianglesAllow: { TriangleId: '', TriangleClearance: '', TriangleName: '' },
        PublishProcedureAllow: [],
        newsLinkField: {
          value: '',
          error: false
        }
      }

      jQuery('#edit-field-compartmentalization input').val(JSON.stringify(groups));
      this.setState({ groups });
      return;
    }

    this.getIntellitemValues(value);
  }

  render() {
    return (
      <div className="compartmentalization">
        <span className="title">{this.props.title}</span>

        {
          this.state.includeNewsLinkField &&
          <div className="news-link-section">
            <div className="classification">
              <span className="label">קישור לידיעה</span>
              <input
                ref="text"
                onChange={(e) => this.onBlurNewsLinkField(e.target.value)}
                id="news-link-field"
                defaultValue={this.state.groups.newsLinkField?.value}
              />
              {
                this.state.groups.newsLinkField?.error &&
                <InputLabel
                  error={true}
                  size="small"
                  id="news-link-error"
                >
                  קישור לידיעה לא קיים
                </InputLabel>
              }
            </div>
          </div>
        }

        <div className="section">
          <div className="source wrapper-select-item">
            <span className="label">
              מקור: <span className="star">*</span>
            </span>
            <AsyncSelect
              className="select-item"
              value={this.state.groups.source}
              loadOptions={(e) => this.state.option_true ? this.promiseOptions(e) : []}
              onInputChange={(e) => { this.setState({ option_true: e.length >= 3 }) }}
              onChange={(e) => this.handlAclChangeSource(e)}
            />
          </div>

          {this.state.groups.TrianglesAllow &&
            <div id='triangle'>
              <span>{this.state.groups.TrianglesAllow.TriangleName}</span>
              <span>{this.state.groups.TrianglesAllow.TriangleClearance} </span>
            </div>
          }

          <div className="classification wrapper-select-item">
            <span className="label">סיווג:</span>
            <TextField
              className="select-item"
              disabled
              value={this.state.groups.Classification}
              margin="normal"
            />
          </div>
        </div>

        <div className="section">
          <div className="wrapper-select-item">
            <span>נוהל הפצה:</span>
            <Select
              className="select-item"
              value={this.state.groups.PublishProcedureAllow}
              onChange={(e) => this.handlAclChange(e)}
              options={this.state.api_result2}
            />
          </div>
        </div>
      </div>
    );
  }
}

Compartmentalization.propTypes = {

};

export default Compartmentalization;
