import React, { Component } from 'react';
import './style.scss';

class IronImage extends Component {

  constructor(props) {
    super(props);
    this.ironImageHd = null;
  }

  componentDidMount() {
        
    const hdLoaderImg = new Image();

    hdLoaderImg.src = this.props.srcLoaded;

    hdLoaderImg.onload = () => {
      this.ironImageHd.setAttribute(
        'src',
        `${this.props.srcLoaded}`
      );
      this.ironPreImageHd.setAttribute(
        'style',
        'display: none;'
      );
      this.ironImageHd.classList.add('iron-image-fade-in');
    }

  };

  render() {
    return (
      <div className="iron-image-container">      
      <img className="iron-image-loaded" ref={imageLoadedElem => this.ironImageHd = imageLoadedElem} />
      <div className = "warpper-image-preload">
        <img className="iron-image-preload" 
          src = {this.props.srcPreload}  
          ref={imagePreLoadedElem => this.ironPreImageHd = imagePreLoadedElem}/>
      </div>      
      </div>
    )
  }

}

export default IronImage;