import React from 'react';
import { Collapse, Typography } from '@material-ui/core';

class Collapsible extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: false,
        };
    }

    handleToggleCollapse = () => {
        this.setState((prevState) => ({
            collapsed: !prevState.collapsed,
        }));
    };

    renderCollapsed = (items) => {
        const { collapsed, exceededHeight } = this.state;    
        return (
            <div>
                <button onClick={this.handleToggleCollapse}>
                    {collapsed ? 'לסגירה' : 'לצפיה'}
                </button>
                <Collapse in={collapsed} className='collapsed'>
                    <div
                        ref={(node) => {
                            this.collapseRef = node;
                        }}
                        style={{ maxHeight: exceededHeight ? '100px' : 'none', overflowY: 'auto' }}
                    >
                        {items.map((obj, index) => (
                            <Typography key={index}>
                                {Object.values(obj)[0].join(',')}
                            </Typography>
                        ))}
                    </div>
                </Collapse>
            </div>
        );
    };
    

    render() {
        return this.renderCollapsed(this.props.items);
    }
}

export default Collapsible;
