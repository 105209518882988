import React from 'react';
import PropTypes from 'prop-types';
import gql from "graphql-tag";

//style
import '../InnerPageFooter/style.scss'
import '../../Utils/bxslider/jquery.bxslider.css';


//Componnets
import Article from "../Article/index";
    
class Piwik extends React.Component {

  constructor(props) {
    
    super(props);
    this.state = {
      articles: []
    };
    let query;
    if(this.props.show_most_viewed_articles){
      query = gql`
        {
          piwik(limit:"${props.filter_limit}"){
            id
            main_title_homepage   
            main_title_inner_page 
            status 
            created
            date_update
            article_type
            description_homepage
            video_homepage
            extension_video_homepage
            classification
            unit{
              name
              icon
            } 
            image_src
            video_url
            image_video
            image_size
            path
          }
        }
      `;
    }

    else
      query = gql`
      {
        entity(ids:[${props.nodes_obj}]){
          main_title_homepage   
          status 
          created
          date_update
          article_type
          description_homepage
          video_homepage
          extension_video_homepage
          classification
          unit{
            name
            icon
          } 
          image_src
          video_url
          image_video
          image_size
          path
        }
      }
      `;
    myApolloClient.query({ query }).then(result => {
      let articles = this.props.show_most_viewed_articles ? result.data.piwik: result.data.entity;
      articles = articles.filter(item => item && item.status == '1');
      this.setState({articles},this.runTicker);
    })

  }

  runTicker(){
    this.bxSlider && this.bxSlider.destroySlider();

    this.bxSlider = jQuery(this.myBxslider).bxSlider({
        slideMargin: 10,
        slideWidth: 280,
        adaptiveHeight:true,
        maxSlides: 3,
        pager: false
      });
  }

  render() {
    return (
      <div className="piwik">
        <div className="pane-title">{this.props.title}
          <a className="view-more" href={this.props.link}>{this.props.link_title}</a>
        </div>
        <div className="bxslider" ref={el => this.myBxslider = el}>
          {!!this.state.articles.length && this.state.articles.map((item, key)=> (
            <Article
            key={key}
            {...item}
            />
              )
          )}
        </div>
      </div>
    );
  }
}

Piwik.propTypes = {

};

export default Piwik;
