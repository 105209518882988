import React from 'react';
import gql from "graphql-tag";
import MetaTags from 'react-meta-tags';
import { goTo403 } from '../../Utils/ConvertFunctions';
import MaozModal from './MaozModal';
import _ from 'lodash';
import CircularProgress from '@mui/material/CircularProgress';

const isInIframe = window.self !== window.top;

//Style
import './style.scss';
class MaozPage extends React.Component {

  allGroups = JSON.parse(window.Drupal?.settings?.rp_maoz?.maoz_chat_groups) || {};

  constructor(props) {
    super(props);
    this.state = {
      groups: [],
      title: '',
      maozBody: -1,
      open: false,
      sentError: [],
      filteredGroups: [],
      sent: false,
      loading: true,
      field_important_news: false,
      gold_pdf: ""
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleState = this.handleState.bind(this);

    const query = gql`
    {
      entity(ids:[{id:${props.nid}, style:"${props.build_mode}"}]){
        title
        id
        created
        body
        news_link
        maoz_more_links
        arena
        title_long
        classification
        triangle
        triangleClearance
        publishProcedureAllow
        field_important_news
        gold_pdf {
          type
          name
          url
        }
        triangleName
      }
    }
    `;

    myApolloClient.query({ query }).then(result => {
      if (!result.data?.entity?.[0]?.id) {
        goTo403(result.data?.entity?.[0]);
      }
      const maozBody = this.buildMaozContent(result.data);
      const defaultGroups = result.data?.entity?.[0]?.arena?.chatIds;
      // const entity = result.data?.entity;
      let groups = defaultGroups?.map((val) => {
        return Object.keys(this.allGroups).find(key => this.allGroups[key] === val);
      });
      groups = groups?.filter(item => item !== undefined);
      let entity = result?.data?.entity;
      this.handleState('groups', groups || []);
      this.setState({ maozBody });
      this.setState({ title: (result.data?.entity?.[0]?.title_long) ? result.data?.entity?.[0]?.title_long : result.data?.entity?.[0]?.title, id: String(entity?.[0]?.id), triangle: String(entity?.[0]?.triangle) || "null", classification: String(entity?.[0]?.classification) || "null", triangleClearance: String(entity?.[0]?.triangleClearance) || "null", publishProcedureAllow: String(entity?.[0]?.publishProcedureAllow) || "null" });
      this.setState({ loading: false });
      result.data?.entity?.[0]?.field_important_news && this.setState({ field_important_news: true });
      result.data?.entity?.[0]?.gold_pdf && this.setState({ gold_pdf: result.data?.entity?.[0]?.gold_pdf });


    });
  }

  buildMaozContent(data) {
    let maozBody = null;
    let maozPDF = null;

    if (data?.entity?.[0]) {
      const title = (data?.entity?.[0]?.title_long) ? data?.entity?.[0]?.title_long : data?.entity?.[0]?.title;
      const gold_pdf = data?.entity?.[0]?.gold_pdf;
      //console.log(gold_pdf);
      maozBody = '<strong>' + title + '</strong>' +
        '<br>' + data?.entity?.[0]?.body +
        `<a href=${data?.entity?.[0]?.news_link} target="_blank">` + data?.entity?.[0]?.news_link + '</a><br>';

      data?.entity?.[0]?.maoz_more_links?.map((link) => {
        maozBody += `<a href=${link} target="_blank">` + link + '</a><br>';
      });


      if (gold_pdf?.url) {
        maozPDF = `<div class="attachments">
            <span class="attachments-title"> ידיעת זהב - PDF</span>
              <div class="row-file">
                <div class="pdf-file-icon file-icon"></div>
                <div class="file-info">
                  <a
                    href=${gold_pdf.url}
                    target="_blank">
                    ${gold_pdf.name}
                    </a>
                   </div>
                </div>
            </div>

          `
      }

      maozBody += gold_pdf?.url ? maozPDF + '<strong>עדכוני מעו"ז</strong>' : '<br><strong>עדכוני מעו"ז</strong>'
    }

    return maozBody;

  }

  handleClose() {
    this.handleState('open', false)
    if (this.state.sent) {
      this.handleState('sent', false);

      if (window.parent !== window) {
        // The script is running inside an iframe
        //message sent closing iframe
        setTimeout(function () {
          var allIframes = top.document.querySelectorAll('[id^="maozNewsflash"]');
          for (var i = 0; i < allIframes.length; i++) {
            var element = allIframes[i];
            //console.log("Element ID: " + element.id);
            element.parentNode.removeChild(element);
          }
        }, 500);
      }
    }

    if (this.state.sentError) {
      this.handleState('sentError', []);
    }
  }

  handleState(stateName, stateVal) {
    this.setState({ [stateName]: stateVal })
  }
  render() {
    return (
      <>
        {this.state.loading ? (<CircularProgress classs={"progress"} color="error" size={70} sx={{
          position: 'absolute',
          top: '50%',
          right: '50%',
          transform: 'translate(50%, -50%)', // Center the CircularProgress both vertically and horizontally
          fontWeight: 'bold',
          fontSize: 14,
          zIndex: 'tooltip'
        }}
        />) : <></>}

        <div className="maoz-page">
          {this.state.maozBody != -1 && (this.state.maozBody && this.state.maozBody && isInIframe && window.Drupal?.settings?.rp_maoz?.userIsLoggin ?


            <div className="maoz-page-content">
              <MetaTags>
                <title>{this.state.title}</title>
                <meta
                  id="og-title"
                  property="og:title"
                  content={this.state.title}
                />
              </MetaTags >
              <div className="maoz-message">
                <div
                  className="content-body"
                  dangerouslySetInnerHTML={{ __html: this.state.maozBody }}
                >
                </div>
              </div>

              <button
                className="chat-send maoz-btn"
                onClick={() => this.handleState('open', true)}
              >
                בחר קבוצות להפצה ב-HiChat
              </button>

              <MaozModal
                open={this.state.open}
                handleClose={this.handleClose}
                maozBody={this.state.maozBody}
                sent={this.state.sent}
                sentError={this.state.sentError}
                groups={this.state.groups}
                allGroups={this.allGroups}
                handleState={this.handleState}
                id={this.state.id}
                triangle={this.state.triangle}
                classification={this.state.classification}
                triangleClearance={this.state.triangleClearance}
                publishProcedureAllow={this.state.publishProcedureAllow}
                filteredGroups={this.state.filteredGroups}
                field_important_news={this.state.field_important_news}
                gold_pdf={this.state.gold_pdf}
              />

            </div> :

            goTo403('NotAllowd'))}
        </div>
      </>
    );
  }
}

export default MaozPage;

