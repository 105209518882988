import React from 'react';
import PropTypes from 'prop-types';
import gql from "graphql-tag";

import Article from "../Article/index";
import './style.scss';

class HpSectionsArticles extends React.Component {

  constructor(props) {
      super(props);
      this.state = {
        largeArticle: {},
        smallArticles: []
      };
      let allowedArticles = [];
      const length = props.nodes_groups.length && props.nodes_groups[0] && props.nodes_groups[0].length;
      const query = gql`
      {
        entity(ids:[${props.nodes_obj}]){
          main_title_homepage
          main_title_inner_page
          status
          created
          date_update
          article_type
          description_homepage
          video_homepage
          extension_video_homepage
          classification
          is_blue
          image_video
          unit{
            name
            icon
          }
          image_src
          video_url
          image_video
          image_size
          path
        }
        view(viewName:"last_articles_by_personal",displayId:"services_1", argument:"${length}",limit:10){
          entity{
            id
            status
            main_title_homepage
            main_title_inner_page
            created
            date_update
            article_type
            description_homepage
            classification
            is_blue
            red_title
            unit{
              name
              icon
            }
            image_src
            video_url
            image_video
            image_size
            path
          }
        }
      }
    `;
    
    myApolloClient.query({ query}).then(result => {
      allowedArticles = result.data.entity;
      let defaultArticles = result.data.view.entity;
      let correctArticles = [];
      let defaultIndex = 0;
      // Set empty articles from nodes_groups to allowedArticles.
      // Facilitate comparison.
      props.nodes_groups.length && props.nodes_groups.map((item, key) => {
        item.length && item.map((nid, i) => {
          if(!nid)
          allowedArticles.splice(key * props.nodes_groups[0].length + i, 0, nid);
        });
      });

      allowedArticles && allowedArticles.length && allowedArticles.map((item, key) => {
        // If the article is allowed
        if(key < props.nodes_groups[0].length) {
          if(item?.path) {
            correctArticles.push(item);
          }
          // If the article is not allowed
          else {
            let isCorrect = false;
            for (let i = key + props.nodes_groups[0].length; i < allowedArticles.length && !isCorrect; i+= props.nodes_groups[0].length) {
              if(allowedArticles[i]?.path) {
                correctArticles.push(allowedArticles[i]);
                isCorrect = true;
              }
            }
            !isCorrect && defaultArticles && defaultArticles.length && defaultArticles.length > defaultIndex && correctArticles.push(defaultArticles[defaultIndex ++]);
          }
        }
      });

      let smallArticles = correctArticles;
      let largeArticle =  correctArticles.shift();
      smallArticles = smallArticles.filter(item => !item || item.status == '1');
      largeArticle = !largeArticle || largeArticle.status == '1'? largeArticle: null;
      this.setState({ smallArticles,largeArticle });
    });
  }

  render() {
    return (
      !!this.state.largeArticle &&
      <div className="hp-sections-articles">
        <div className="pane-title">{this.props.title}
          <a className="view-more" href={this.props.link}>{this.props.link_title}</a>
          </div>
          {!!this.state.largeArticle &&
          <div className="large-article">
          <Article {...this.state.largeArticle} />
        </div>}
        <div className="small-articles">
        {!!this.state.smallArticles.length && this.state.smallArticles.map((item, key) =>
              <Article
                key = {key}
                { ...item}
              />
            )}
        </div>
      </div>
    );
  }
}

HpSectionsArticles.propTypes = {

};

export default HpSectionsArticles;
