import React from 'react';
import gql from "graphql-tag";
import { Modal } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import Collapsible from "../../hooks/useCollapsed"
import _, { result } from 'lodash';
import CircularProgress from '@mui/material/CircularProgress';


//Style
import './style.scss';

let usersNotAllowedForRoomId = [];
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 500,
        },
    },
};
let filteredGroups = [];
let filteredGroupsNotSendingChat = [];
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};
const rederTextToSend = (text) => {
    const regex = /<div class="attachments">[\s\S]*?<\/div>\s*|<div class="file-info">[\s\S]*?<\/div>\s*/g;
    let renderedText = text.replace(regex, '');
    renderedText = renderedText.replace(/>\s+</g, '><');
    renderedText = renderedText.replaceAll('"', '&quot;').replaceAll('\n', '</br>')
    return renderedText;
}


class MaozModal extends React.Component {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleSend = this.handleSend.bind(this);
        this.renderUsersNotAllowed = this.renderUsersNotAllowed.bind(this);
        this.renderError = this.renderError.bind(this);
        this.state = {
            loading: false,
            graphError: false,
        };

    }

    renderGroups(groups) {
        return (
            <>
                {groups?.map((group, index) => (
                    <Typography key={index} variant="h6" component="p">
                        • {group}
                    </Typography>
                ))}
            </>
        );
    }

    renderUsersNotAllowed() {
        const textStyle = {
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        };

        return (
            <>
                <p style={textStyle}>היוזרים שאינם מורשים הם:</p>
                <Collapsible items={usersNotAllowedForRoomId} />
            </>
        );
    }
    handleChange(event) {
        const {
            target: { value },
        } = event;

        this.props.handleState('groups', typeof value === 'string' ? value.split(',') : value)
    };

    handleSend() {
        this.setState({ loading: true, graphError: false }); // Set loading state to true

        const rids = this.props.groups.map((val) => {
            return this.props.allGroups[val];
        });
        const message = rederTextToSend(this.props.maozBody);

        const query = gql`
        {
          sendChatMessage(
            message: "${message}",
            roomIds: ${JSON.stringify(rids)},
            id: ${JSON.stringify(this.props?.id)},
            triangle: ${JSON.stringify(this.props?.triangle)},
            classification: ${JSON.stringify(this.props?.classification)},
            triangleClearance: ${JSON.stringify(this.props?.triangleClearance)},
            publishProcedureAllow: ${JSON.stringify(this.props?.publishProcedureAllow)},
            gold_pdf: {
              name: ${JSON.stringify(this.props?.gold_pdf?.name)},
              url: ${JSON.stringify(this.props?.gold_pdf?.url)}
            },
            field_important_news: ${JSON.stringify(this.props?.field_important_news)}
          )
        }
      `;

        myApolloClient.query({ query, fetchPolicy: "no-cache" }).then(result => {

            let updatedGroups = [];
            usersNotAllowedForRoomId = result.data.sendChatMessage.usersNotAllowedForRoomId;
            const roomsIdsToRemove = usersNotAllowedForRoomId.map((obj) => Object.keys(obj)[0]);
            for (const key in this.props.allGroups) {
                if (roomsIdsToRemove.includes(this.props.allGroups[key])) {
                    updatedGroups.push(key);
                }
            }
            // Filter out values that are present in updatedGroups
            filteredGroupsNotSendingChat = this.props.groups.filter(group => updatedGroups.includes(group));
            filteredGroups = this.props.groups.filter(group => !updatedGroups.includes(group));
            this.props.handleState('filteredGroups', filteredGroups);

            // Update filteredGroups with the errorGroup 
            let errors = _.filter(result.data.sendChatMessage.result[0], (res) => { return res?.status != '200' });
            if (errors.length) {
                this.props.handleState('sentError', errors);
                Object.entries(errors).map(([index, err]) => {
                    let errorGroup = Object.keys(this.props.allGroups)?.find(key => {
                        if (this.props.allGroups[key] === err.roomId) {
                            return this.props.allGroups[key];
                        }
                    });
                    filteredGroups = filteredGroups?.filter(group => group !== errorGroup);
                    this.props.handleState('filteredGroups', filteredGroups);
                })
            }
            else {
                if (!result.data.sendChatMessage.result[0].length && !filteredGroupsNotSendingChat.length) {
                    this.setState({ loading: false });
                    this.setState({ graphError: true });
                    this.props.handleState('sentError', []);
                }
                else {

                    this.props.handleState('sentError', []);
                }
            }
            this.props.handleState('sent', true);
            this.setState((prevState) => {
                return { loading: false };
            });
        }).catch(error => {
            this.props.handleState('sentError', error.message);
            this.setState({ loading: false });

        });
    }

    renderError() {
        return (
            <>
                <Typography
                    sx={{ fontWeight: 'bold' }}
                    id="modal-modal-title"
                    variant="h6"
                    component="h1"
                    className='chose-groups-title'
                >
                    אירעה שגיאת תקשורת בשליחת ההודעה לקבוצות:
                </Typography>
                {
                    Object.entries(this.props.sentError).map(([index, err]) => (
                        <Typography
                            key={index}
                            variant="h6"
                            component="p"
                        >
                            • {Object.keys(this.props.allGroups)?.find(key => this.props.allGroups[key] === err.roomId)}
                        </Typography>
                    ))
                }
                <Typography
                    variant="h6"
                    component="p"
                >
                    אנא פנה למנהל האתר
                </Typography>
            </>
        )
    }

    renderResultSending() {
        return (
            <>
                <Box sx={style} className="chose-groups">
                    {this.props.sentError.length != 0 && this.renderError()}

                    {
                        this.props.filteredGroups.length ?
                            <>
                                <Typography
                                    sx={{ fontWeight: 'bold' }}
                                    variant="h6"
                                    component="h1"
                                    id="modal-modal-title"
                                    className='chose-groups-title'
                                >
                                    הידיעה נשלחה בהצלחה לקבוצות:
                                </Typography>


                                {this.renderGroups(this.props.filteredGroups)}
                            </> : ""
                    }

                    {
                        filteredGroupsNotSendingChat.length ?
                            <>
                                <Typography
                                    sx={{ fontWeight: 'bold' }}
                                    variant="h6"
                                    component="h1"
                                    id="modal-modal-title"
                                    className='chose-groups-title'
                                >
                                    אירעה שגיאת מידור בשליחת ההודעה לקבוצות:
                                </Typography>


                                {this.renderGroups(filteredGroupsNotSendingChat)}
                                {this.renderUsersNotAllowed()}
                            </> : ""
                    }
                    <button
                        className="chat-send maoz-btn"
                        onClick={this.props.handleClose}
                    >
                        אישור
                    </button>
                </Box>
            </>
        )
    }

    render() {
        return (
            <Modal
                open={this.props.open}
                onClose={this.props.handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                {
                    !this.props.sent ?
                        <Box sx={style} className="chose-groups">
                            <Typography
                                sx={{ fontWeight: 'bold' }}
                                id="modal-modal-title"
                                variant="h6"
                                component="h1"
                                className='chose-groups-title'
                            >
                                בחר קבוצות להפצה ב-HiChat
                            </Typography>

                            <FormControl sx={{ m: 1, width: 550 }}>
                                <InputLabel id="demo-multiple-checkbox-label">קבוצות להפצה</InputLabel>
                                <Select
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    multiple
                                    value={this.props.groups}
                                    onChange={this.handleChange}
                                    input={<OutlinedInput label="קבוצות להפצה" />}
                                    renderValue={(selected) => selected.join(', ')}
                                    MenuProps={MenuProps}
                                >
                                    {
                                        Object.keys(this.props.allGroups).map((group) => (
                                            <MenuItem
                                                key={group}
                                                value={group}
                                            >
                                                <Checkbox
                                                    checked={this.props.groups?.indexOf(group) > -1}
                                                />
                                                <ListItemText
                                                    primary={group}
                                                />
                                            </MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                            <>
                                {this.state.loading ? (
                                    <div className="maoz-loader">

                                        <CircularProgress color="primary" size={40} />
                                        <p>המערכת סורקת את רמת ההרשאות של המשתמשים, אנא המתן מספר שניות...  </p>

                                    </div>) : (
                                    <button
                                        className="chat-send maoz-btn"
                                        onClick={this.handleSend}
                                        disabled={this.props.groups.length ? false : true}
                                    >
                                        שלח ל- HiChat
                                    </button>
                                )}
                            </>
                        </Box>
                        : this.renderResultSending()
                }
            </Modal>
        )
    }
}

export default MaozModal;
