import React from "react";
import L from "leaflet";
import { fetchData } from "../LeafletMap/internalMap";

class MapEdit extends React.Component {
  constructor(props) {
    super(props);
    this.map = null
    this.initializeMap(31.452096498984, 34.453114614951, 11)
  }

  // Call this function where it's needed in your component, e.g., useEffect for initial data load
  initializeMap = async (lat, lng, zoom) => {
    if (lat && lng) {
      if (!this.map) {
        let { urlTemplate, footprintGeoJSON, desired_layer_identifier, tileMatrixSet, extent } = await fetchData();

        const TOKEN = Drupal.settings.CRComponentsSettings?.react_app_config.MAP_TOKEN;

        const parser = (urlTemplate) => {
          return urlTemplate
            .replace("{TileMatrixSet}", tileMatrixSet)         // from Step_4
            .replace("{TileMatrix}", "{z}")
            .replace("{TileRow}", "{y}")
            .replace("{TileCol}", "{x}");
        }
        console.log(extent)
        const bounds = L.latLngBounds([
          [extent[1], extent[0]],                                         // from Step_2
          [extent[3], extent[2]],                                         // from Step_2
        ]);

        const parsedUrl = parser(urlTemplate)

        this.map = L.map("map", { crs: L.CRS.EPSG4326 }).setView([lat, lng], zoom);

        // const geoJsonLayer = L.geoJSON(footprintGeoJSON);
        // geoJsonLayer.addTo(this.map);
        // this.map.fitBounds(geoJsonLayer.getBounds());

        const layer = L.tileLayer(parsedUrl, {   // received RASTER auth token
          id: desired_layer_identifier,
          bounds                        // from Step_3
        })
        this.map.addLayer(layer);
        // Define a custom event
        var mapReadyEvent = new CustomEvent('map-ready', { detail: { map: this.map } });

        // Trigger the event after map initialization
        document.dispatchEvent(mapReadyEvent);

      } /* else {
        // If the map is already initialized, just set the view to the new coordinates
        this.map.setView([lat, lng], zoom);
      } */
    }
  };

  render() {
    return (
      <>
        <div className="map-news-container">
          <div className="map-container">
            <div id="map" className="leaflet-map-container"></div>
          </div>
        </div>
      </>
    )
  }

}

export default MapEdit;
