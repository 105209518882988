import gql from "graphql-tag";

const setUserInMatomo = () => {

    const query = gql`{userData}`;

    myApolloClient.query({ query }).then(result => {
        const user = result.data.userData;
        if (user) {
            setDataInMatomo(['setUserId', user.uid]);
            user.source && setDataInMatomo(['setCustomDimension', 1, user.source], false);
            user.jobTitle && setDataInMatomo(['setCustomDimension', 2, user.jobTitle], false);
            user.rank && setDataInMatomo(['setCustomDimension', 3, user.rank], false);
        }
    });
}

const setTitleAndUrlInMatomo = (title) => {
    // const { href } = window.location;
    // title != '' && title && setDataInMatomo(['setCustomUrl', `${href}?${title}`], true);
    // title != '' && title && setDataInMatomo(['setDocumentTitle', title], true);
}

const setDataInMatomo = (data, trackPageView) => {
    const _paq = window._paq || [];
    if (_paq) {
        _paq.push(data);
        if (trackPageView) {
            _paq.push(['trackPageView']);
        }
    }
}

export {
    setTitleAndUrlInMatomo,
    setUserInMatomo,
    setDataInMatomo
} 
