import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';
import SocialButtons from '../SocialButtons/index'
import moment from 'moment';

moment.locale('he');
class InnerPageHeader extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};

  }

  render() {
    return (
        <div className={`inner-page-header ${this.props.is_blue === "1" ? 'is-blue' : ''}`}>
           {this.props.is_blue === "1" && <div className="blue-top-banner">הינך צופה באתר בסיווג סודי ביותר - כחול</div>}
            <div className="header-content">
              <div className="title">
                {!!this.props.article_type && this.props.article_type == 'opinion' && 
                <span className="opinion_title">טור דעה: </span>}
                <span>{this.props.main_title_inner_page}</span>
                <span className="description">{this.props.description_inner_page}</span>
              </div>
              <SocialButtons {...this.props }/>
            </div>
            <div className="extra-details">
            {!!this.props.article_type && !!this.props.article_type == 'opinion' && 
              <div className="writer-img">
                <a href={`/search?writer=${this.props.writer_id}`}>
                  <img  src={this.props.writer_image}/>
                </a>
              </div>
              }
              <div className="sub-header-area">
                <div className='sub-header-area-right'>
                  {this.props.writer_name && <div className = "wrap-details">
                    <span >נכתב: </span>
                    <span className="details-value">{this.props.writer_name}</span>
                  </div>}
                  {this.props.confirmed && <div className = "wrap-details">
                    <span >תואם: </span>
                    <span className="details-value" >{this.props.confirmed}</span>
                  </div>}
                  {this.props.tested && <div className = "wrap-details">
                    <span >נבדק: </span>
                    <span className="details-value">{this.props.tested}</span>
                  </div>}
                  {this.props.compatible_with && <div className = "wrap-details">
                    <span >אושר: </span>
                    <span className="details-value" >{this.props.compatible_with}</span>
                  </div>}
                </div>
                <div>
                  {this.props.reference && <div className = "wrap-details reference">
                    <span >סימוכין: </span>
                    <span className="details-value">{this.props.reference}</span>
                  </div>}
                </div>
              </div>
            </div>
            <hr />
            <div className="sub-header-area">
              <div className="department">
                  <div className="units">
                      {!!this.props.unit && this.props.unit.map((item, key)=> (
                         <div className="unit" key={key}> <img src={item.icon} alt={item.name}></img>
                            <span className="tooltip">{item.name}</span>
                          </div>
                          )
                      )}
                  </div>
                {this.props.classification && <div className = "wrap-details">
                  <div className="classification details-value">
                    <span className="details"> סיווג: </span>
                    <span className="value">{this.props.is_blue === "1" ? 'כחול' : this.props.classification}</span>
                    <span className="tooltip">{this.props.triangleName}</span>
                  </div>  
                </div>} 
              </div>
              <div className="date-details">
              {this.props.reading_time && <div className = "wrap-details">
                <span>זמן קריאה: </span>
                <span className="details-value">{this.props.reading_time}</span>  
              </div>}           
                <div className = "wrap-details">
                  <span> עודכן ב: </span>
                  <span className="details-value">
                    {this.props.date_update ? moment(this.props.date_update*1000).format("D.M.YYYY HH:mm") : moment(this.props.created*1000).format("D.M.YYYY HH:mm")}
                  </span> 
                </div>
              </div>   
            </div>
        </div>
    );
  }
}

InnerPageHeader.propTypes = {
};

export default InnerPageHeader;
