export const createMarkup = (description) => {
  description = description && description.replace(/↵/g, "");
  let htmlObject = document.createElement("div");
  htmlObject.innerHTML = description;
  return { __html: description };
};

export const nodeTypeOptions = {
  bureau_update: "עדכון לשכות",
  daily_component: "סיכום יומי",
  newsflash: "מבזק",
  article: "כתבה",
};

export const articleTypeOptions = {
  article: "כתבה",
  opinion: "כתבת דעה",
  video: "כתבת וידאו",
};

export const validURL = (str) => {
  var pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
};

export const getImageSrc = (item) => {
  var image = item["field_video_image:file:url"];

  if (
    item["field_images:field_image:file:url"] &&
    item["field_images:field_image:file:url"].length
  )
    if (Array.isArray(item["field_images:field_image:file:url"])) {
      image = item["field_images:field_image:file:url"][0];
    } else {
      image = item["field_images:field_image:file:url"];
    }
  var type = item.node_type ? item.node_type : item.type;
  switch (type) {
    case "daily_component":
      return Drupal.settings.image_search_daily_component;
    case "newsflash":
      return Drupal.settings.image_search_newsflash;
    default:
      return image ? image : item.image_src;
  }
};

export const goTo403 = (entity) => {
  console.log(entity)
  const encodeGetParams = p =>
    Object.entries(p).map(kv => kv.map(encodeURI).join("=")).join("&")

  let params = {}
  if (typeof entity === 'string' && entity == 'NotAllowd') {
    params = {
      source: 'not-allowd'
    }
  }
  else {
    params = {
      source: (entity?.classification !== undefined) ? entity.classification : '',
      triangle: (entity?.triangleName !== undefined) ? entity.triangleName : '',
      distribution: (entity?.publishProcedureAllow !== undefined && entity.publishProcedureAllow !== null) ? entity.publishProcedureAllow : 'אין',
    }
  }

  const url = '/' + Drupal.settings.site_403 + '?' + encodeGetParams(params)
  window.location.href = url;
};
