import React from "react";
import "leaflet/dist/leaflet.css"; // move it later to the proper scss file
import gql from "graphql-tag";
import CircularProgress from "@mui/material/CircularProgress";
import "./style.scss";
import locationIcon from "../../assets/img/location.png";
import linkIcon from "../../assets/img/external-link.png";
import L from "leaflet";
import { fetchData } from "./internalMap";


// const REACT_APP_COOKIE = "9964564006cc14c489744e37ace71ff5=a6ebc4f8d03805b0c86dde4481d2161b"

class LeafletMap extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      title: "",
      loading: false,
      zirot: [],
    };

    this.map = null; // will hold the map instance
    this.markersGroup = L.layerGroup(); // initialize markersGroup here
    this.handleZiraClick = this.handleZiraClick.bind(this);

    const query = gql`
    {
      entity(ids:[{id:${props.nid}, style:"full"}]){
        title
        id
        mapCenter{
          lat
          lng
          zoom
        }
        mapMarkers{
          id
          lat
          lng
          title
          link
          icon
          description
          date
        }
        zirot {
          id
          name
          lat
          lon
          markers {
            id
            lat
            lng
            title
            link
            icon
            description
            date
          }
        }
      }
    }
    `;


    myApolloClient.query({ query }).then((result) => {
      if (!result.data?.entity?.[0]?.id) {
        this.setState({
          id: null,
          mapCenter: null,
          zirot: [],
          loading: false
        });
        return;
      }
      const mapCenter = {
        lat: parseFloat(result.data?.entity?.[0]?.mapCenter?.lat),
        lng: parseFloat(result.data?.entity?.[0]?.mapCenter?.lng),
        zoom: parseFloat(result.data?.entity?.[0]?.mapCenter?.zoom),

      };
      const mapMarkers = result.data?.entity?.[0]?.mapMarkers.map((marker) => ({
        ...marker,
        lat: parseFloat(marker.lat),
        lng: parseFloat(marker.lng),
      }));

      const entity = result?.data?.entity;
      this.setState({
        id: String(entity?.[0]?.id),
        mapCenter: mapCenter,
        zirot: result.data?.entity?.[0]?.zirot
      }, this.updateMarkers(mapCenter.lat, mapCenter.lng, mapCenter.zoom, mapMarkers));
      this.setState({ loading: false });
    });
  }

  handleZiraClick(markers, lat, lng) {
    this.updateMarkers(lat, lng, 12, markers);
  }



  // Call this function where it's needed in your component, e.g., useEffect for initial data load
  initializeMap = async (lat, lng, zoom) => {
    if (lat && lng) {
      if (!this.map) {
        let { urlTemplate, footprintGeoJSON, desired_layer_identifier, tileMatrixSet, extent } = await fetchData();

        const TOKEN = Drupal.settings.CRComponentsSettings?.react_app_config.MAP_TOKEN;

        const parser = (urlTemplate) => {
          return urlTemplate
            .replace("{TileMatrixSet}", tileMatrixSet)         // from Step_4
            .replace("{TileMatrix}", "{z}")
            .replace("{TileRow}", "{y}")
            .replace("{TileCol}", "{x}");
        }
        console.log(extent)
        const bounds = L.latLngBounds([
          [extent[1], extent[0]],                                         // from Step_2
          [extent[3], extent[2]],                                         // from Step_2
        ]);

        const parsedUrl = parser(urlTemplate)

        this.map = L.map("map", { crs: L.CRS.EPSG4326 }).setView([lat, lng], zoom);

        // const geoJsonLayer = L.geoJSON(footprintGeoJSON);
        // geoJsonLayer.addTo(this.map);
        // this.map.fitBounds(geoJsonLayer.getBounds());

        const layer = L.tileLayer(parsedUrl, {   // received RASTER auth token
          id: desired_layer_identifier,
          bounds                        // from Step_3
        })
        this.map.addLayer(layer);

      } else {
        // If the map is already initialized, just set the view to the new coordinates
        this.map.setView([lat, lng], zoom);
      }
      this.map.addLayer(this.markersGroup); // Add the markersGroup to the map
      // Add event listener for zoom change
      this.map.on("zoomend", this.handleZoomChange);

    }
  };


  // Function to handle zoom change event
  handleZoomChange = () => {
    const currentZoom = this.map.getZoom();
    console.log("Current zoom level:", currentZoom);
  };

  updateMarkers = (lat, lng, zoom, mapMarkers) => {
    this.markersGroup.clearLayers(); // Clear existing markers
    // const customIcon = L.icon({
    //   iconUrl: locationIcon, // If you imported the image
    //   //iconUrl: "../images/location.png", // Use this line if your project does not support imports for static assets
    //   iconSize: [40, 40], // Size of the icon
    //   iconAnchor: [20, 40], // Point of the icon which will correspond to marker's location
    //   popupAnchor: [0, -40], // Point from which the popup should open relative to the iconAnchor
    // });

    mapMarkers?.forEach((markerData) => {
      if (markerData.lat && markerData.lng) {
        const popupContent = `
      <div class="custom-popup">
        <img class="popup-image" src="${markerData.image}" alt="Image description"/>
        <div class="popup-text-content">
          <a href="${markerData.link}" target="_blank" class="popup-link-button">
            <img src="${linkIcon}" alt="Location Icon" />
          </a>
          <h1>${markerData.title}</h1>
          <div class="popup-description">${markerData.description}</div>
          <div class="popup-date">${markerData.date}</div>
        </div>
      </div>`;

        const marker = L.marker([markerData.lat, markerData.lng], {
          icon: L.icon({
            iconUrl: markerData.icon || locationIcon, // If you imported the image
            //iconUrl: "../images/location.png", // Use this line if your project does not support imports for static assets
            iconSize: [40, 40], // Size of the icon
            iconAnchor: [20, 40], // Point of the icon which will correspond to marker's location
            popupAnchor: [0, -40], // Point from which the popup should open relative to the iconAnchor
          }),
        }).bindPopup(popupContent, {
          className: "my-custom-popup",
          maxWidth: 400, // Set the max width to fit the content
        });
        this.markersGroup.addLayer(marker); // Add new marker to the group
      }
    });
    this.initializeMap(lat, lng, zoom);

  };

  componentWillUnmount() {
    if (this.map) {
      this.map.remove(); // Clean up the map to avoid memory leaks
    }
  }

  render() {
    if (!this.state.id) {
      return null;
    }
    return (
      <>
        {this.state.loading ? (
          <CircularProgress
            className={"progress"} //looks like a mistake to me ..classs should be className
            color="error"
            size={70}
            sx={{
              position: "absolute",
              top: "50%",
              right: "50%",
              transform: "translate(50%, -50%)", // Center the CircularProgress both vertically and horizontally
              fontWeight: "bold",
              fontSize: 14,
              zIndex: "tooltip",
            }}
          />
        ) : (
          <></>
        )}
        <>
          <div className="map-news-container">
            <div>
              {this.state?.zirot?.map((zira) => {
                return (
                  <button
                    key={zira.name}
                    className="zira-btn"
                    onClick={() => this.handleZiraClick(zira.markers, zira.lat, zira.lon)}
                  >
                    {zira.name}
                  </button>
                );
              })}
            </div>
            <div className="map-container">
              <div id="map" className="leaflet-map-container"></div>
            </div>
          </div>
        </>
      </>
    );
  }
}

export default LeafletMap;
