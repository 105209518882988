import React from 'react';
import PropTypes from 'prop-types';
import gql from "graphql-tag";

import Article from "../Article/index";
import {getImageSrc} from '../../Utils/ConvertFunctions';
import moment from 'moment'
moment.locale('he');

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from 'react-loader-spinner';

import '../SectionLobbyArticles/style.scss';
import './style.scss';

const LIMIT = 15;

class AllContentPage extends React.Component {

  constructor(props) {
      super(props);
      this.state = {
        results: [],
        offset: 1,
        total: -1,
        maxLength: false,
        isLoad: true
      };
      this.loading = false;
      this.handleScroll= this.handleScroll.bind(this);
      this.getData =this.getData.bind(this);

      Api.addListener(this.getData);
  }

  componentDidMount(){
    Api.searchObj.offset = this.state.offset;
    Api.searchObj.limit = LIMIT;
    Api.searchObj.fieldsSearch = [{"tid" : "node_types" , "query": ["article", "daily_component", 'bureau_update']}];
    Api.searchQuery();
    window.addEventListener('scroll', this.handleScroll,false);
  }

  componentWillMount(){
    var loaded_locale = require('moment/locale/' + 'he' + '');
    moment.locale('he', loaded_locale);
    window.removeEventListener('scroll', this.handleScroll,false);
  }

  handleScroll(){
    // Checks that the page has scrolled to the bottom
    if((jQuery(window).scrollTop() + jQuery(window).height() > jQuery(document).height() - 1 )) {
      if(this.state.total > this.state.offset * LIMIT)
        this.loadMore();
    }
  }

  getData(data){
    let results = data.data.elasticsearch.searchEntity.reduce((acc, item) =>
    item['_source'].status === '1' ? [...acc ,item['_source']] : ''
      ,
      []
      );
    this.setState({total: data.data.elasticsearch.total, results, isLoad: false});
  }

    loadMore(){
      const offset =  this.state.offset + 1;
      this.setState({offset, isLoad: true});
      Api.searchObj.offset = offset;
      Api.searchQuery();
    }

    calcDate(item,i){
        const createdDay = moment(item.created*1000).format("ddd DD.MM.YYYY");
        const createdDayPrevContent = this.state.results[i-1]? moment(this.state.results[i-1].created*1000).format("ddd DD.MM.YYYY"):'';
        if((createdDayPrevContent !=''&& createdDayPrevContent!= createdDay)||i==0){
            return <div className="date-title">יום {createdDay} </div>
        }
        return '';
    }

  render() {
    return (
      <div className="all-content-page">
          <div className={`pane-content ${this.state.isLoad? 'pane-content-loading' : ''}`}>
            {this.state.results && this.state.results.length > 0 && this.state.results.map((item, key)=> (
                <div className = "content" key={key}>
                    {this.calcDate(item,key)}
                    <Article
                        main_title_homepage={item.field_front_page_title}
                        main_title_inner_page={item.title}
                        newsflash_content={item.field_newsflash}
                        node_type={item.type}
                        created={item.created}
                        article_type={item.field_article_type}
                        description_homepage={item.field_front_page_intro}
                        classification={JSON.parse(item['field_compartmentalization']).Classification}
                        is_blue={item.field_is_blue}
                        unit={item['field_unit:name'][0]}
                        path={item.url}
                        image_src = {getImageSrc(item)}
                        image_video = {getImageSrc(item)}
                        key={key}
                    />
                </div>
            )
            )}
            <Loader type="ThreeDots" color="#0f4963" height={120} width={120} visible={this.state.isLoad} className="loader"/>
        </div>
      </div>
    );
  }
}

AllContentPage.propTypes = {

};

export default AllContentPage;
