import React from "react";
import Article from "../Article/index";
import { getImageSrc } from "../../Utils/ConvertFunctions";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

//Style
import "./style.scss";
import "../SectionLobbyArticles/style.scss";

const LIMIT = 10;

class SearchResults extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      results: [],
      offset: 1,
      total: -1,
      aa: "",
      isLoad: true,
    };
    this.getData = this.getData.bind(this);

    Api.addListener(this.getData);
  }

  getData(data) {
    let results = data.data.elasticsearch.searchEntity.reduce(
      (acc, item) =>
        item["_source"].status === "1" ? [...acc, item["_source"]] : acc,
      []
    );

    this.setState({
      total: data.data.elasticsearch.total,
      results,
      isLoad: false,
    });
  }

  LoadMoreRsults() {
    const offset = this.state.offset + 1;
    this.setState({ offset, isLoad: true });
    Api.searchObj.offset = offset;
    Api.searchQuery();
  }

  componentDidMount() {
    Api.searchObj.offset = this.state.offset;
    Api.searchObj.limit = LIMIT;
    Api.searchQuery();

    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("key");
    Api.searchKey = token;
  }

  render() {
    return (
      <div className="search-results">
        {this.state.total != -1 &&
          (this.state.total == 0 ? (
            <div className="no-result">לא נמצאו תוצאות</div>
          ) : (
            <div>
              <div className="total">
                <span>{this.state.total}</span>תוצאות חיפוש
              </div>
              <div className="article-list">
                {this.state.results &&
                  this.state.results.length &&
                  this.state.results.map((item, key) => (
                    <Article
                      id={item.id}
                      main_title_homepage={item.field_front_page_title}
                      main_title_inner_page={item.title}
                      newsflash_content={item.field_newsflash}
                      node_type={item.type}
                      created={item.created}
                      article_type={item.field_article_type}
                      description_homepage={item.field_front_page_intro}
                      classification={
                        JSON.parse(item["field_compartmentalization"])
                          .Classification
                      }
                      is_blue={item.field_is_blue}
                      unit={item["field_unit:name"][0]}
                      path={item.url}
                      image_src={getImageSrc(item)}
                      image_video={getImageSrc(item)}
                      key={key}
                    />
                  ))}
              </div>
            </div>
          ))}

        {this.state.total > this.state.offset * LIMIT && (
          <div className="btn-show-more">
            <div
              className="show-more-results"
              onClick={() => {
                this.LoadMoreRsults();
              }}
            >
              הצג עוד
            </div>
          </div>
        )}
        <Loader
          type="ThreeDots"
          color="#0f4963"
          height={120}
          width={120}
          visible={this.state.isLoad}
          className="loader"
        />
      </div>
    );
  }
}

SearchResults.propTypes = {};

export default SearchResults;
