import React from 'react';
import PropTypes from 'prop-types';
import gql from "graphql-tag";

//Componnets
import InnerPageHeader from '../InnerPageHeader/index';
import InnerPageFooter from '../InnerPageFooter/index';
import Article from '../Article';
import { goTo403 } from '../../Utils/ConvertFunctions';
import MetaTags from 'react-meta-tags';
import { setDataInMatomo, setTitleAndUrlInMatomo } from '../../Utils/matomo'

//Style
import './style.scss';

//Vars
let videoStartTime = false;
let videoWatchedTime = 0;
class BureauUpdate extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      playVideo: false,
      BureauUpdateData: -1,
      queryProps: [],
      comment_count: ''
    };

    const query = gql`
    {
      entity(ids:[{id:${props.nid},style:"${props.build_mode}"}]){
        main_title_inner_page
        id
        description_inner_page
        writer_id
        writer_name
        writer_image
        writer_description
        confirmed
        tested
        compatible_with
        vote_count
        hide_description
        hide_image
        reference
        video_text
        reference_links{
          title
          url
        }
        reading_time
        created
        date_update
        confirmed
        image_src
        image_desc
        galery_images {
          src
          desc
        }
        video_url
        image_video
        quote
        quote_source
        summary
        body
        article_type
        article_reference {
          id
          main_title_homepage
          image_src
          path
          article_type
          image_video
        }
        tags {
          id
          name
        }
        unit{
          name
          icon
        }
        attached_files{
          name
          url
          type
          size
        }
        classification
        is_blue
        triangle
        triangleName
        comment_status
        comments{
          cid
          pid
          name
          subject
          body
          mail
          created
          displayName
        }
        gizrot{
          image
          description
        }
        credit
        publishProcedureAllow
      }
    }
   `;

    myApolloClient.query({ query }).then(result => {
      let comment_count = result.data.entity.length && result.data.entity[0] && result.data.entity[0].comments ? result.data.entity[0].comments.length : 0;
      this.setState({ BureauUpdateData: result.data.entity[0], comment_count }, this.runTicker);

      // Set classification & writer name in matomo.
      // this.state.BureauUpdateData.classification && setDataInMatomo(['trackEvent', 'סיווג', this.state.BureauUpdateData.classification]);
      // this.state.BureauUpdateData.writer_name && setDataInMatomo(['trackEvent', 'זירה', this.state.BureauUpdateData.writer_name]);
    });
  }

  runTicker() {
    this.bxSlider && this.bxSlider.destroySlider();
    this.bxSlider = jQuery(this.myBxslider).bxSlider({
      slideWidth: 860,
      adaptiveHeight: true,
      maxSlides: 1,
      pager: false
    });
  }


  createMarkup() {
    let body = '';
    const gizrot = this.state?.BureauUpdateData?.gizrot;
    if (gizrot.length === 0) {
      body = this.state?.BureauUpdateData?.summary + this.state?.BureauUpdateData?.body;
    }
    else {
      body = this.state?.BureauUpdateData?.summary;
      gizrot.map((item) => {
        let divElement = document.createElement('div');
        divElement.className = 'gizra';
        // Create an image element
        var imgElement = document.createElement('img');
        imgElement.src = item?.image;
        if (imgElement.getAttribute('src') !== '') {
          // Append the image and text elements to the div
          divElement.appendChild(imgElement);
        }
        divElement.innerHTML += item?.description;
        body += divElement.innerHTML;
      })
    }
    if (this.state?.BureauUpdateData?.credit) {
      body += this.state?.BureauUpdateData?.credit;
    }

    const quote_source = (this?.state?.BureauUpdateData?.quote_source) ? this?.state?.BureauUpdateData?.quote_source : '';
    const quoteBody = (this?.state?.BureauUpdateData?.quote) ? this?.state?.BureauUpdateData?.quote : '';
    let quote = (quoteBody) ?'<div class="quote-wrapper"><div class="quote-icon"></div><div class="border"></div><div class="quote">' + quoteBody + '<div class="quote-source">' + quote_source + '</div></div></div>' : '';
    body = body.replace('[quote]', quote);
    let htmlObject = document.createElement('div');
    htmlObject.innerHTML = body;
    return { __html: body };
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', (event) => this.handleTabClose(event));
  }

  handleTabClose(event) {
    event.preventDefault();

    // Set whatched video time.
    if (videoWatchedTime || videoStartTime) {
      if (videoStartTime !== 0) {
        this.videoOnPause(event);
      }
      const minutes = `0${Math.floor(videoWatchedTime / 1000 / 60)}`.slice(-2);
      const sec = `0${Math.floor(videoWatchedTime / 1000 % 60)}`.slice(-2);
      setDataInMatomo(['trackEvent', 'זמן צפייה בוידאו', this.state.BureauUpdateData.main_title_inner_page, `${minutes}:${sec}`]);
    }

    return;
  };

  videoAddEvent(body) {
    let allVideo = body.querySelectorAll('video');
    Object.values(allVideo).map((video, index) => {
      video.addEventListener('play', (event) => this.videoOnPlay(event))
      video.addEventListener('pause', (event) => this.videoOnPause(event))
    })
  }

  componentDidMount() {
    window.addEventListener('beforeunload', (event) => this.handleTabClose(event));

    setTimeout(function () {
      let body = document.querySelector('.bureau-update-page .body')
      if (body) {
        let images = body.querySelectorAll('img');
        for (let i = 0; i < images.length; i++) {
          if (images[i].getAttribute('longdesc')) {
            let el = images[i];
            let wrapper = document.createElement('div');
            wrapper.className = 'wrap-img';
            el.parentNode.insertBefore(wrapper, el);
            wrapper.appendChild(el);
            let desc = document.createElement('div');
            desc.className = 'description';
            desc.innerHTML = images[i].getAttribute('longdesc');
            images[i].parentNode.insertBefore(desc, images[i].nextSibling)
          }
        }

        this.videoAddEvent(body);
      }
    }
      .bind(this),
      1000);
  }

  videoOnPlay(event) {
    videoStartTime = event.timeStamp;
    this.setState({ playVideo: true })
  }

  videoOnPause(event) {
    const time = Math.floor(event.timeStamp - videoStartTime);
    videoStartTime = 0;
    videoWatchedTime += time;
    this.setState({ playVideo: false });
  }

  render() {
    return (
      <div
        className={ `bureau-update-page ${this.state.BureauUpdateData && this.state.BureauUpdateData.is_blue === "1" ? 'is-blue' : ''}` }
      >
        {
          this.state.BureauUpdateData != -1 &&
          (
            this.state.BureauUpdateData && this.state.BureauUpdateData.id ?
              <div>
                <InnerPageHeader
                  { ...this.state.BureauUpdateData }
                  comment_count={ this.state.comment_count }
                />
                <MetaTags>
                  <title>
                    { this.state.BureauUpdateData.main_title_inner_page }
                  </title>
                  <meta
                    id="og-title"
                    property="og:title"
                    content={ this.state.BureauUpdateData.main_title_inner_page }
                  />
                </MetaTags>
                {
                  setTitleAndUrlInMatomo(this.state.BureauUpdateData.main_title_inner_page)
                }
                <div
                  className="content-body"
                >
                  {
                    !!this.state.BureauUpdateData.hide_image &&
                    this.state.BureauUpdateData.hide_image != '1' &&
                    (
                      <div>
                        {
                          this.state.BureauUpdateData.article_type &&
                            this.state.BureauUpdateData.article_type == 'video' ?
                            <div
                              className="wrap-video"
                            >
                              <video
                                onPlay={ (e) => this.videoOnPlay(e) }
                                onPause={ (e) => this.videoOnPause(e) }
                                className={ this.state.playVideo ? 'video-play' : 'video-stop' }
                                width="400"
                                controls
                                poster={ this.state.BureauUpdateData.image_video }
                              >
                                <source
                                  src={ this.state.BureauUpdateData.video_url }
                                  type="video/mp4" />
                              </video>
                              {
                                this.state.BureauUpdateData.video_text &&
                                this.state.BureauUpdateData.video_text !== '' &&
                                (
                                  <p
                                    className="description">
                                    { this.state.BureauUpdateData.video_text }
                                  </p>
                                )
                              }
                            </div>
                            :
                            !!this.state.BureauUpdateData.galery_images &&
                            !!this.state.BureauUpdateData.galery_images.length &&
                            <div
                              className="bxslider"
                              ref={ el => this.myBxslider = el }>
                              {
                                this.state.BureauUpdateData.galery_images.map((item, key) => (
                                  <Article
                                    key={ key }
                                    image_src={ item.src }
                                    description_homepage={ item.desc }
                                    { ...item }
                                  />
                                ))
                              }
                            </div>
                        }
                      </div>
                    )
                  }
                  {

                    //!!this.state.BureauUpdateData.body &&
                    <div
                      className="body"
                    >
                      <span>
                        <div
                          dangerouslySetInnerHTML={ this.createMarkup() }
                        >
                        </div>
                      </span>
                    </div>
                  }
                </div>
                <InnerPageFooter
                  { ...this.state.BureauUpdateData }
                  onComment={ (comment_count) => { this.setState({ comment_count }); } }
                />
              </div>
              :
              goTo403(this.state?.BureauUpdateData))
        }
      </div>
    );
  }
}

BureauUpdate.propTypes = {

};

export default BureauUpdate;
