import React from 'react';
import gql from "graphql-tag";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'

import './style.scss'
const close = 1;
const open = 2;
class CommentForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      value: this.props.body,
      user: '',
      mail: ''
    };
    this.btnClicked = false

    const query = gql`{user}`;
    myApolloClient.query({ query }).then(result => {
      if (result.data.user)
        this.setState({ user: result.data.user });
    });

  }

  handleSubmit() {
    if (this.btnClicked) {
      return
    } else {
      this.btnClicked = true;
    }

    // event.preventDefault();
    if (this.props.comment_status == close) {
      confirmAlert({
        message: "הכתבה סגורה לתגובות, יש לפנות למנהל האתר לפתיחת הכתבה לתגובות",
        buttons: [{ label: 'OK' }]
      });
      this.props.onClose();
      this.btnClicked = false;
      this.setState({ value: '' });
    }
    else {
      let value = this.textValue.value.replace(/"/g, '\\\"');
      value = value.replace(/(?:\r\n|\r|\n)/g, '\\n');
      let user = this.state.user.replace(/"/g, '\\\"');
      const processedValue = value.replace(/\\/g, '');

      let mutate;
      if (this.props.cid != "0")
        mutate = gql`
          mutation{
            comment(comment:{
              cid: "${this.props.cid}"
              body: "${processedValue}"
            },
            method: "PUT"){
              cid
              pid
              name
              subject
              body
              created
              displayName
            }}
          `;

      else
        mutate = gql`
        mutation{
          comment(comment:{
              subject: "${processedValue}"
              nid: "${this.props.nid}"
              pid: "${this.props.pid}"
              body: "${processedValue}"
              name: "${user}"
              displayName: "${this.props?.displayName}"
            },
            method: "POST"){
              cid
              pid
              name
              mail
              subject
              body
              created
              displayName
            }
            }
          `;

      myApolloClient.mutate({ mutation: mutate }).then(result => {
        if (result.data.comment.cid) {
          this.props.onSave(result.data.comment);
          this.btnClicked = false;
          this.setState({ value: '' });

        }
        else
          console.log(result.data.comment);
      });
    }
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  render() {
    return (
      <div className={`comments-form ${this.props.cid != "0" || this.props.pid != "0" && 'form-indent'}`} id="comments-form" >
        <span className="title">  שמך:</span>  <div className="name">{this.state.user}</div>
        <label className="title">שתפו את דעתכם</label>
        <textarea
          rows="4" cols="50"
          value={this.state.value}
          onChange={(e) => { this.handleChange(e) }}
          ref={(value) => this.textValue = value}></textarea>
        <div className="wrap-button">
          <button
            className="submit"
            onClick={(e) => this.handleSubmit(e)}
            disabled={!this.state.value} >שמור</button>
        </div>
      </div>
    );
  }
}

CommentForm.propTypes = {
};

export default CommentForm;
