import React from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import moment from 'moment'
moment.locale('he');

//Componnets
import Modal from '@material-ui/core/Modal';
import { createMarkup } from '../../Utils/ConvertFunctions';
import { setDataInMatomo } from '../../Utils/matomo'

//Style
import "./style.scss";

class Newsflash extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newsflash: [],
      direction: this.props.check_newsflash,
      speed: this.props.check_newsflash == 'horizontal' ? 500 : 600,
      autoDirection: this.props.check_newsflash == 'horizontal' ? "prev" : "next",
      open: false,
      item: {}
    };

    const section_id = (this.props.section_id !== undefined) ? this.props.section_id : ''

    const query = gql`
    {
      view(viewName:"newsflash",displayId:"services_${this.props.check_newsflash}",argument:"${section_id}"){
        entity{
          id
          main_title_inner_page
          created
          classification
          is_blue
          newsflash_content
          show_newsflash
          show_newsflash_sidebar
          unit{
            name
            icon
          }
        }
      }
    }
    `;

    myApolloClient.query({ query }).then(result => {
      let newsflash = this.sortByDate(result.data.view.entity);

      newsflash = newsflash.filter((item) => {
        return (item.id !== null)
      })

      if (this.state.direction == 'horizontal') {
        newsflash = newsflash.slice(0, 6);
        newsflash.reverse();
        //newsflash.push(newsflash.shift());
      }

      // Fix console error if no newsflash.
      if (newsflash.length > 0) {
        this.setState({ newsflash },
          this.runTicker)
      }

    });
  }

  sortByDate(result) {
    result.sort(function (x, y) {
      return y.created - x.created;
    })
    return result;
  }

  runTicker() {
    this.bxSlider && this.bxSlider.destroySlider();
    const { direction, speed, autoDirection } = this.state;
    var panelModalContent = jQuery('#modalContent').length > 0;
    var panels_edit = jQuery('.panels-ipe-editing').length > 0;

    if (!panelModalContent || !panels_edit) {
      this.bxSlider = jQuery(this.myBxslider)?.bxSlider({
        mode: direction,
        auto: 0,
        stopAutoOnClick: 0,
        controls: 0,
        hideControlOnEnd: 1,
        moveSlides: 1,
        pager: 0,
        speed: speed,
        ticker: 1,
        tickerHover: 1,
        useCSS: 0,
        startingSlide: 0,
        autoDirection: autoDirection,
      });
    }
    jQuery(`.newsflash.${direction} .newsflesh-slider.bxslider .newflash`).on('click', (event) => {
      let itemId = event.currentTarget.dataset.itemId;
      this.setState({ open: true, item: this.state.newsflash[itemId] });
    })
  }

  render() {
    const { newsflash, direction } = this.state;
    if (newsflash.length == 0) return null;
    return (
      <div className={ `newsflash ${direction}` }>
        <div className="pane-content">
          <div className="pane-title"><a href="/newsflash">{ this.props.title }</a> </div>
          <ul className={ `newsflesh-slider bxslider` } ref={ el => this.myBxslider = el }>
            { !!newsflash && newsflash.map((item, key) =>
              <li
                key={ key }
                className={ `newflash ${item.is_blue === '1' ? 'is-blue' : ''}` }
                data-item-id={ key }
              >
                <span className="date-created">{ moment(item.created * 1000).format('LT') }</span>
                { item.main_title_inner_page }
              </li>
            ) }
          </ul>
        </div>
        { this.state.open && <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={ true }
          onClose={ () => this.setState({ open: false }) }
          onBackdropClick={ () => this.setState({ open: false }) }
        >
          <div className="modal">
            <div className="close" onClick={ () => this.setState({ open: false }) }>X</div><br />
            <div className="top-modal">
              <div className="pane-title">{ this.props.title }</div>
            </div>
            {
              // this.state.item.classification && setDataInMatomo(['trackEvent', 'סיווג', this.state.item.classification])
            }
            <div className={ `newflash ${this.state.item.is_blue === '1' ? 'is-blue' : ''}` }>
              <div className="modal-title">{ this.state.item.main_title_inner_page }</div>
              <div className="description"
                dangerouslySetInnerHTML={ createMarkup(this.state.item.newsflash_content) }>
              </div>
              <hr />
              <div className="article-info">
                יחידה: <span className="unit">
                  { !!this.state.item.unit && !!this.state.item.unit.length && this.state.item.unit[0].name }
                </span>
                סיווג: <span className="ttl">{ this.state.item.is_blue === '1' ? 'כחול' : this.state.item.classification ? this.state.item.classification : 'לא מסווג' } </span>
                <span className="created">{ !!this.state.item.created && moment(this.state.item.created * 1000).format('LT') }</span>
                <span className="created">{ !!this.state.item.created && moment(this.state.item.created * 1000).format("DD.MM.YYYY") }</span>
              </div>
            </div>
          </div>
        </Modal> }
      </div>
    );
  }
}

Newsflash.propTypes = {};

export default Newsflash;
