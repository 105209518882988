import React from "react";
import PropTypes, { element } from "prop-types";
import gql from "graphql-tag";

import Article from "../Article/index";
import "../HpSectionsArticles/style.scss";
import "./style.scss";

class HPMainArticles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      largeArticle: {},
      smallArticles: [],
    };
    let allowedArticles = [];
    const length =
      props.nodes_groups &&
      props.nodes_groups.length &&
      props.nodes_groups[0] &&
      props.nodes_groups[0].length;
    const query = gql`
      {
        entity(ids:[${props.nodes_obj}]){
          id
          status
          main_title_homepage
          main_title_inner_page
          created
          date_update
          article_type
          description_homepage
          video_homepage
          extension_video_homepage
          classification
          is_blue
          red_title
          unit{
            name
            icon
          }
          image_src
          video_url
          image_video
          image_size
          path
        }

        view(viewName:"last_articles_by_personal",displayId:"services_1", argument:"${length}", style:"hp_small_article",limit:10){
          entity{
            id
            status
            main_title_homepage
            main_title_inner_page
            created
            date_update
            article_type
            description_homepage
            extension_video_homepage
            video_homepage
            classification
            is_blue
            red_title
            unit{
              name
              icon
            }
            image_src
            video_url
            image_video
            image_size
            path
          }
        }
      }
    `;

    myApolloClient.query({ query, fetchPolicy: "no-cache" }).then((result) => {
      allowedArticles = result.data.entity.filter((item) => {
        return item?.id !== null;
      });
      let defaultArticles = allowedArticles;
      let correctArticles = [];
      let correctIds = [];
      let defaultIndex = 0;
      // Set empty articles from nodes_groups to allowedArticles.
      // Facilitate comparison.
      props.nodes_groups &&
        props.nodes_groups.length &&
        props.nodes_groups.map((item, key) => {
          item.length &&
            item.map((nid, i) => {
              if (!nid)
                allowedArticles.splice(
                  key * props.nodes_groups[0].length + i,
                  0,
                  nid
                );
            });
        });

      props.nodes_groups &&
        props.nodes_groups.length &&
        allowedArticles &&
        allowedArticles.length &&
        allowedArticles.map((item, key) => {
          // If the article is allowed
          if (key < props.nodes_groups[0].length) {
            if (item) {
              correctArticles.push(item);
              correctIds.push(item.id);
            }
            // If the article is not allowed
            else {
              let isCorrect = false;
              for (
                let i = key + props.nodes_groups[0].length;
                i < allowedArticles.length && !isCorrect;
                i += props.nodes_groups[0].length
              ) {
                if (allowedArticles[i]) {
                  correctArticles.push(allowedArticles[i]);
                  correctIds.push(allowedArticles[i].id);
                  isCorrect = true;
                }
              }

              !isCorrect && correctArticles.push(null);
            }
          }
        });

      // Set in not allow article default article from low classification.
      correctArticles.forEach((element, index) => {
        if (!element && defaultArticles && defaultArticles.length)
          for (; defaultArticles.length > defaultIndex; defaultIndex++) {
            if (!correctIds.includes(defaultArticles[defaultIndex]?.id)) {
              correctArticles.splice(index, 1, defaultArticles[defaultIndex]);
              correctIds.push(defaultArticles[defaultIndex]?.id);
              break;
            }
          }
      });

      let smallArticles = correctArticles;
      let largeArticle = correctArticles.shift();
      smallArticles = smallArticles.filter(
        (item) => !item || item.status == "1"
      );
      largeArticle =
        !largeArticle || largeArticle.status == "1" ? largeArticle : null;
      this.setState({ smallArticles, largeArticle });
    });
  }

  createSixArticle() {
    let mediumArticle = [];
    let smallArticle = [];
    let blockArticles = [];
    for (let i = 0; i < 2; i++) {
      this.state.smallArticles[i] &&
        mediumArticle.push(
          <Article key={i} {...this.state.smallArticles[i]} />
        );
    }
    for (let i = 2; i < 6; i++) {
      this.state.smallArticles[i] &&
        smallArticle.push(<Article key={i} {...this.state.smallArticles[i]} />);
    }
    blockArticles.push(
      <div key="0" className="medium-small-articles">
        <div className="meduim-articles">{mediumArticle}</div>
        <div className="small-articles">{smallArticle}</div>
      </div>
    );
    return blockArticles;
  }

  render() {
    return (
      !!this.state.largeArticle &&
      !!this.state.smallArticles?.length && (
        <div className={`main-articles ${this.props.main_article_type}`}>
          {!!this.props.title && (
            <div className="pane-title">
              {this.props.title}
              <a className="view-more" href={this.props.link}>
                {this.props.link_title}
              </a>
            </div>
          )}
          {!!this.state.largeArticle && (
            <div className="large-article">
              <Article
                main_article_type={this.props.main_article_type}
                {...this.state.largeArticle}
              />
            </div>
          )}
          {this.props.main_article_type == "main_articles_six" &&
          this.state.smallArticles?.length ? (
            this.createSixArticle()
          ) : (
            <div className="small-articles">
              {!!this.state.smallArticles.length &&
                this.state.smallArticles.map(
                  (item, key) => item && <Article key={key} {...item} />
                )}
            </div>
          )}
        </div>
      )
    );
  }
}

// HPMainArticles.propTypes = {
//   large_id: PropTypes.string,
//   content_bottom_1: PropTypes.string,
//   content_bottom_2: PropTypes.string,
//   content_bottom_3: PropTypes.string,
// };

export default HPMainArticles;
