import React from "react";
import ReactDOM from "react-dom";
import 'url-search-params-polyfill';
import { setUserInMatomo } from './src/Utils/matomo';

import Api from 'services/api';
// import LazyLoad from "vanilla-lazyload";
console.log("test")

// Setup ApolloClient
import ApolloClient from "apollo-boost";


// window.myLazyLoad = new LazyLoad({
// auto_unobserve:false
// });

// Register component function
window.initCRComponent = function (settings) {

  window.myApolloClient = new ApolloClient({
    uri: settings.client_render_graphql_url,
    shouldBatch: true,
    request: function (operation) {
      const token = Drupal.settings.user_name;
      operation.setContext({
        headers: {
          authorization: encodeURI('test'),//token ? `${token}` : '',
          // charset: "utf-8",
        }
      });
    }
  });
  window.Api = new Api();
  setUserInMatomo();
}

// Register component function
window.registerComponent = function (name, component) {
  // create "CRComponents" object
  !window.CRComponents && (window.CRComponents = {});

  // set CRComponents render function for each [componentName]
  CRComponents[name] = function (el, settings) {
    ReactDOM.unmountComponentAtNode(el);
    ReactDOM.render(React.createElement(component, settings), el);
  }
}


// Register component function
window.testComponent = function (name, settings) {
  // create "CRTestElements" object
  !window.CRTestElements && (window.CRTestElements = {});
  if (!window.CRTestElements[name]) {
    window.CRTestElements[name] = document.createElement("div");
    document.body.append(window.CRTestElements[name]);
  }
  setTimeout(() => CRComponents[name](window.CRTestElements[name], settings));
}

