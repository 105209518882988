import React from 'react';
import EditGroupArticle from '../EditGroupArticle';
import Button from '@material-ui/core/Button';
import { ReactSortable } from "react-sortablejs";
import {getImageSrc} from '../../Utils/ConvertFunctions';
//Style

import './style.scss';

class EditHpMainArticles extends React.Component {

 constructor(props) {
   super(props);
   this.state = {
      options: [],
      option_true: false,
      groups :[{articles:[]}],
   };
   this.onClickAddGroup = this.onClickAddGroup.bind(this);
   this.onClickRemoveGroup = this.onClickRemoveGroup.bind(this);
   this.onChangeGroups = this.onChangeGroups.bind(this); 
   this.onChangeNode = this.onChangeNode.bind(this); 
   this.handleChangeFields = this.handleChangeFields.bind(this);
  }

  onClickAddGroup(){
    let tempGroups;
    switch (this.props.main_article_type) {
      case 'main_articles_full':
          tempGroups = {articles: [{option:[],content:[]},{option:[],content:[]},{option:[],content:[]},{ option:[],content:[]},{option:[],content:[]}]};
          break;
      case 'main_articles_six':
          tempGroups = {articles: [{option:[],content:[]},{option:[],content:[]},{option:[],content:[]},{ option:[],content:[]},{option:[],content:[]},{option:[],content:[]},{option:[],content:[]}]};
          break;
      default:
          tempGroups = {articles: [{option:[],content:[]},{option:[],content:[]},{ option:[],content:[]},{option:[],content:[]}]};
    }
    this.setState((prevState) => {
    prevState.groups.unshift(tempGroups);
      return prevState;
    });
  }

  onClickRemoveGroup(index){
    this.setState((prevState) => {
      let newGroup = prevState.groups.splice(index,1);
      jQuery('[name=save_json_group_article]').val(JSON.stringify(this.state.groups));
      return newGroup;
    });
  }

  handleChangeFields(value, groupIndex, articleIndex){
      value = value['_source'];
      let imageUrl = getImageSrc(value);
      let comp = value['field_compartmentalization'] ? JSON.parse(value['field_compartmentalization']): '';
      comp &&
      this.setState((prevState) => {
      prevState.groups[groupIndex].articles[articleIndex].content = {title: value.title, image: imageUrl, classification: comp.Classification, triangle: comp.TrianglesAllow.TriangleName};
      prevState.groups[groupIndex].articles[articleIndex].option = {label: value.id +'/'+ value.title, value:value.id};
      jQuery('[name=save_json_group_article]').val(JSON.stringify(prevState.groups));
      return prevState;
    });
   }

   onChangeNode(e, groupIndex){
     this.setState((prevState) => {
        let tmp = {};
       tmp.content = prevState.groups[groupIndex].articles[e.oldIndex].content;
       tmp.option = prevState.groups[groupIndex].articles[e.oldIndex].option; 
       prevState.groups[groupIndex].articles[e.oldIndex].content = prevState.groups[groupIndex].articles[e.newIndex].content;
       prevState.groups[groupIndex].articles[e.oldIndex].option = prevState.groups[groupIndex].articles[e.newIndex].option; 
       prevState.groups[groupIndex].articles[e.newIndex].content =  tmp.content;
       prevState.groups[groupIndex].articles[e.newIndex].option =  tmp.option;
       jQuery('[name=save_json_group_article]').val(JSON.stringify(prevState.groups));
       return prevState;
     });
    jQuery('[name=save_json_group_article]').val(JSON.stringify(this.state.groups));
  } 

  onChangeGroups(){
     jQuery('[name=save_json_group_article]').val(JSON.stringify(this.state.groups));
   }

 componentWillMount(){
   this.setSelected()
 }

 setSelected(){
  this.setState((prevState) => {
    if(this.props.main_article_type === 'main_articles_full'){
      prevState.groups[0].articles.push({option:[],content:[]},{option:[],content:[]},{option:[],content:[]},{option:[],content:[]},{option:[],content:[]});
    }
    else if(this.props.main_article_type === 'main_articles_six'){
      prevState.groups[0].articles.push({option:[],content:[]},{option:[],content:[]},{option:[],content:[]},{option:[],content:[]},{option:[],content:[]},{option:[],content:[]},{option:[],content:[]});
    }
    else{
      prevState.groups[0].articles.push({option:[],content:[]},{option:[],content:[]},{option:[],content:[]},{option:[],content:[]});
     }
      return prevState;
    });

    let selectedValues = '';
    selectedValues = jQuery('[name=save_json_group_article]').val();
    selectedValues && this.setState((prevState) => {
       return {...prevState, groups: JSON.parse(selectedValues)}
    });
 }

 render() {
   return (
     <div className="cartridges">
          <Button 
            onClick = {this.onClickAddGroup}
						className = "add-button">
						+ הוסף מחסנית
			  </Button>

        <ReactSortable 
          list={this.state.groups}
          setList={newState => {
          this.setState({ groups: newState })}}
          handle='.handle'
          onEnd = {this.onChangeGroups}
          > 
        {this.state.groups && this.state.groups.map((group, index)=> (
          <div  key={index} className="group-for-pane">
          <span className="handle"></span> 
          <div className="groups">
           <span className='title'>מחסנית {index+1}</span>
            <EditGroupArticle
                      key={index}
                      index={index}
                      onClickRemoveGroup={this.onClickRemoveGroup}
                      handleChangeFields={this.handleChangeFields}
                      onChangeNode={this.onChangeNode}
                      group={group.articles}
                    />
          </div> </div>)
            )}
            </ReactSortable>
      </div>
   );
 }
}

EditHpMainArticles.propTypes = {

};

export default EditHpMainArticles;
