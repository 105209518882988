import React from 'react';
import gql from "graphql-tag";
import AsyncSelect from 'react-select/async';
import defaultImage from '../../assets/img/placeholder.svg';

//Style
import './style.scss';

class EditselectArticle extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      options: [],
      option_true: false,
      jsonGroup: [],
    };

    this.promiseOptions = this.promiseOptions.bind(this);
  }

  handleImageErrored() {
    this.setState({ image_src: defaultImage });
  }
  escapeSpecialChars(str) {
    return str.replace(/["]/gi, '\\"')
  }

  promiseOptions(inputValue) {
    const val = this.escapeSpecialChars(inputValue)
    return new Promise(resolve => {
      let query;
      query = gql`
        {
          elasticsearch(fieldsSearch: [{tid: "node_types", query: ["daily_component","article"]}], autocomplete: "${val}") {
            searchEntity
          }
        }`;
      myApolloClient.query({ query }).then(result => {
        let options = result.data.elasticsearch.searchEntity;
        options = options.map(q => (Object.assign(q, { value: q['_source'].id + '/' + q['_source'].title, label: q['_source'].id + '/' + q['_source'].title })));
        this.setState({ [options]: options });
        return resolve(options);
      }).catch(err => {
        console.log('error', err)
      })
    });
  }

  render() {
    return (
      <div className="wrapper-select-item">
        <span><img
          className="img-article"
          src={this.props.article.content.image || defaultImage}
          onError={this.handleImageErrored.bind(this)}
        /></span>
        <span className="label">{this.props.index == 0 ? 'ראשית' : 'כתבה ' + this.props.index}</span>
        <AsyncSelect
          className="select-item"
          key={this.props.index}
          value={this.props.article.option}
          loadOptions={(e) => this.state.option_true ? this.promiseOptions(e) : []}
          onInputChange={(e) => { this.setState({ option_true: e.length >= 3 }) }}
          onChange={(e) => this.props.handleChangeFields(e, this.props.indexGroup, this.props.index)}
        />
        {this.props.article.content && this.props.article.content.classification &&
          <span>{this.props.article.content.classification || ""}</span>
        }
        {this.props.article.content && this.props.article.content.triangle &&
          <span>{this.props.article.content.triangle || ""}</span>
        }
      </div>
    );
  }
}

EditselectArticle.propTypes = {

};

export default EditselectArticle;
