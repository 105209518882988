import React from 'react';
import EditselectArticle from '../EditselectArticle';
import Button from '@material-ui/core/Button';
import { ReactSortable } from "react-sortablejs";

//Style
import './style.scss';

class EditGroupArticle extends React.Component {

 constructor(props) {
   super(props);
   this.state = {
      group: []
   };
  }
 
 render() {
   return (
    <div className="section">
        <Button 
						onClick = {() => this.props.onClickRemoveGroup(this.props.index)}
						className = "remove-article-button">
						הסר מחסנית
			  </Button>
   <ReactSortable 
          list={this.props.group}
          setList={newState => {
        }}
          handle='.handle'
          onEnd= {(e) => this.props.onChangeNode(e, this.props.index)}
          > 
       
      {this.props.group && this.props.group.length > 0 && this.props.group.map((article, index)=> (
        <div  key={index} className="group-for-pane">
          <span className="handle"></span>
         <div>
                <EditselectArticle
                  key={index}
                  index={index}
                  indexGroup={this.props.index}
                  handleChangeFields={this.props.handleChangeFields}
                  article={article}
                /></div></div> )
               
        )}
   </ReactSortable>
      </div>
   );
 }
}

EditGroupArticle.propTypes = {

};

export default EditGroupArticle;
