import React from 'react';
import gql from "graphql-tag";
import PropTypes from 'prop-types';
import Fade from '@material-ui/core/Fade';
import CircularProgress from '@material-ui/core/CircularProgress';
import Modal from '@material-ui/core/Modal';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { setDataInMatomo } from '../../Utils/matomo'
import { PDFDocument, StandardFonts, rgb } from 'pdf-lib';

//Style
import './style.scss';

const bodyMail = 'שלום%2C %0D%0A קראתי משהו שיכול לעניין אותך באתר iNet %0D%0A ';
class SocialButtons extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      rates: [],
      loading: false,
      vote_count: ''
    };

  }

  componentWillMount() {
    this.setState({ vote_count: this.props.vote_count });
  }


  printPdf() {
    var printEl = jQuery('.pane-node-content');
    jQuery(printEl).addClass('print');
    html2canvas(printEl[0]).then(async function (canvas) {
      jQuery(printEl).removeClass('print');
      var imgData = canvas.toDataURL('image/png');
      var imgWidth = 210;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var doc = await PDFDocument.create();
  
      // Add a single page to the PDF document
      const page = doc.addPage();
      page.setSize(imgWidth, imgHeight);
      const pngImage = await doc.embedPng(imgData);
      page.drawImage(pngImage, {
        x: 0,
        y: 0,
        width: imgWidth,
        height: imgHeight,
      });
  
      // Compress the PDF document
      const compressedPdfBytes = await doc.save();
  
      // Create a download link for the compressed PDF
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(new Blob([compressedPdfBytes], { type: 'application/pdf' }));
      downloadLink.download = 'compressed.pdf';
  
      // Trigger the download
      downloadLink.click();
  
      // Clean up the temporary URL
      URL.revokeObjectURL(downloadLink.href);
    });
  }
  

  voting() {
    const mutate = gql`
     mutation{
      voting(nid:${this.props.id})
       }  
     `;
    myApolloClient.mutate({ mutation: mutate }).then(result => {
      if (result.data.voting)
        this.setState({ vote_count: result.data.voting })
    });
  }

  render() {
    return (
      <div className="social-buttons">
        <a className="link comment" href="#comments-form">
          {this.props.comment_count != '0' && <span className="comment-count">{this.props.comment_count}</span>}
        </a>
        <a className="link like" onClick={() => { this.voting() }}>
          <span className="comment-count">{this.state.vote_count || this.props.vote_count}</span>
        </a>
        <a className="link mail" href={`mailto:?subject=${this.props.main_title_inner_page}&body=${bodyMail}${window.location.href}`}></a>
        <a
          className="link pdf"
          onClick={
            () => {
              this.printPdf()
            }
          }
        />
        {/* <a
          className="link pdf"
          onClick={
            () => {
              window.print();
              setDataInMatomo(['trackEvent', 'לחיצה על PDF', this.props.main_title_inner_page]);
            }
          }
        /> */}
        <a
          className="link print"
          onClick={
            () => { window.print() }
          }
        />

        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.loading}
        >
          <Fade
            in={this.state.loading}
            style={{
              transitionDelay: this.state.loading ? '800ms' : '0ms',
            }}
            unmountOnExit
          >
            <CircularProgress className="loading-circular" />
          </Fade>
        </Modal>
      </div>
    );
  }
}

SocialButtons.propTypes = {

};

export default SocialButtons;
