import React from "react";
import moment from "moment";
moment.locale("he");
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

//Utils
import { createMarkup } from "../../Utils/ConvertFunctions";
//Style
import "./style.scss";
//Const
const LIMIT = 20;

class NewsflashPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      itemSelected: {},
      newsflash: [],
      offset: 1,
      maxLength: false,
      isload: true,
    };
    this.loading = false;
    this.handleScroll = this.handleScroll.bind(this);
    this.getData = this.getData.bind(this);

    Api.addListener(this.getData);
  }
  componentDidMount() {
    Api.searchObj.offset = this.state.offset;
    Api.searchObj.limit = LIMIT;
    Api.searchObj.fieldsSearch = [{ tid: "node_types", query: ["newsflash"] }];
    Api.searchQuery();
    window.addEventListener("scroll", this.handleScroll, false);
  }

  scrollToElementFromHash() {
    const hash = window.location.hash.replace("#", "");
    if (hash) {
      const divElement = document.getElementById(hash);
      const liElement = divElement?.querySelector(".newflash");
      if (liElement) {
        liElement.classList.add("highlight");
        divElement.scrollIntoView({ behavior: "smooth", block: "center" });

        // Optionally, remove the highlight class after some time
        setTimeout(() => {
          liElement.classList.remove("highlight");
        }, 2000); // Adjust the time as needed
      } else {
        console.warn(
          `No <li> element with 'newflash' class found inside the div with id '${hash}'.`
        );
      }
    }
  }

  componentWillMount() {
    var loaded_locale = require("moment/locale/" + "he" + "");
    moment.locale("he", loaded_locale);
    window.removeEventListener("scroll", this.handleScroll, false);
  }

  createMarkupZeroWidth(htmlContent) {
    // Remove zero-width spaces from the content
    const sanitizedContent = htmlContent.replace(/\u200B/g, '');
    return { __html: sanitizedContent };
  }

  handleScroll() {
    // Checks that the page has scrolled to the bottom
    if (
      jQuery(window).scrollTop() + jQuery(window).height() >
      jQuery(document).height() - 1
    ) {
      if (this.state.total > this.state.offset * LIMIT) this.loadMore();
    }
  }

  getData(data) {
    let newsflash = data.data.elasticsearch.searchEntity.reduce(
      (acc, item) =>
        item["_source"].status === "1" ? [...acc, item["_source"]] : "",
      []
    );
    this.setState(
      {
        total: data.data.elasticsearch.total,
        newsflash,
        isLoad: false,
      },
      this.scrollToElementFromHash
    );
  }

  loadMore() {
    const offset = this.state.offset + 1;
    this.setState({ offset, isLoad: true });
    Api.searchObj.offset = offset;
    Api.searchQuery();
  }

  calcDate(item, i) {
    const createdDay = moment(item.created * 1000).format("ddd DD.MM.YYYY");
    const createdDayPrevNewflash = this.state.newsflash[i - 1]
      ? moment(this.state.newsflash[i - 1].created * 1000).format(
        "ddd DD.MM.YYYY"
      )
      : "";
    if (
      (createdDayPrevNewflash != "" && createdDayPrevNewflash != createdDay) ||
      i == 0
    ) {
      return <div className="date-title">יום {createdDay} </div>;
    }
    return "";
  }

  updateSelected(i) {
    let newState = Object.assign({}, this.state);
    newState.newsflash[i].selected = !newState.newsflash[i].selected;
    this.setState(newState);
  }

  render() {
    const { newsflash } = this.state;
    return (
      <div className="newsflash-page">
        <div className="pane-content">
          {!!newsflash &&
            !!newsflash.length &&
            newsflash.map((item, i) => (
              <div id={item.id} key={i}>
                {this.calcDate(item, i)}
                <li
                  className={`newflash ${item.field_is_blue === "1" ? "is-blue" : ""
                    }`}
                >
                  <div className="newflash-title-container">
                    <span className="date-created">
                      {moment(item.created * 1000).format("LT")}
                    </span>
                    <div
                      className="newflash-title"
                      onClick={() => {
                        this.updateSelected(i);
                      }}
                    >
                      &nbsp;{item.title}
                    </div>
                  </div>
                  {item.selected ? (
                    <div
                      className="description"
                      dangerouslySetInnerHTML={this.createMarkupZeroWidth(item["field_newsflash:value"])}
                    ></div>
                  ) : (
                    ""
                  )}
                  <div className="article-info">
                    יחידה:{" "}
                    <span className="unit">
                      {!!item.field_unit &&
                        item.field_unit.length &&
                        item["field_unit:name"][0]}
                    </span>
                    סיווג:{" "}
                    <span className="ttl">
                      {item.field_is_blue === "1"
                        ? "כחול"
                        : JSON.parse(item["field_compartmentalization"])
                          .Classification
                          ? JSON.parse(item["field_compartmentalization"])
                            .Classification
                          : "לא מסווג"}{" "}
                    </span>
                  </div>
                </li>
                <hr />
              </div>
            ))}
          <Loader
            type="ThreeDots"
            color="#0f4963"
            height={120}
            width={120}
            visible={this.state.isLoad}
            className="loader"
          />
        </div>
      </div>
    );
  }
}

NewsflashPage.propTypes = {};

export default NewsflashPage;
