import "assets/style/global-styles.scss";

import HpArticlesSidebar from "components/HpArticlesSidebar";
import Newsflash from "components/Newsflash";
import HpArticlesList from "components/HpArticlesList";
import HpImage from "components/HpImage";
import HpSectionsArticles from "components/HpSectionsArticles";
import AritclePage from "components/AritclePage";
import BureauUpdate from "components/BureauUpdate";
import DailyPage from "components/DailyPage";
import Compartmentalization from "components/Compartmentalization";
import NewsflashPage from "components/NewsflashPage";
import imagesList from "components/ImagesList";
import SearchForm from "components/SearchForm";
import SearchResults from "components/SearchResults";
import SectionLobbyArticles from "components/SectionLobbyArticles";
import Piwik from "components/Piwik";
import FreeSearch from "components/FreeSearch";
import HPMainArticles from "components/HPMainArticles";
import CustomPane from "components/CustomPane";
import Favorites from "components/Favorites";
import Menu from "components/Menu";
import HpImageNodequeue from "components/HpImageNodequeue";
import AllContentPage from "components/AllContentPage";
import EditHpMainArticles from "components/EditHpMainArticles";
import MaozPage from "components/MaozPage";
import LeafletMap from "components/LeafletMap";
import Gallery from "components/Gallery";
import MapEdit from "components/MapEdit";

registerComponent("edit_hp_main_articles_full", EditHpMainArticles);
registerComponent("edit_hp_main_articles_ttl_aside", EditHpMainArticles);
registerComponent("edit_hp_sections_articles", EditHpMainArticles);
registerComponent("edit_hp_main_articles_no_txt", EditHpMainArticles);
registerComponent("edit_hp_main_articles_short_txt", EditHpMainArticles);
registerComponent("edit_hp_main_articles_six", EditHpMainArticles);
registerComponent("rp_newsflash_pane", Newsflash);
registerComponent("node_content_maoz_newsflash", MaozPage);
registerComponent("hp_sections_articles", HpSectionsArticles);
registerComponent("views_hp_image", HpImage);
registerComponent("node_content_article", AritclePage);
registerComponent("node_content_bureau_update", BureauUpdate);
registerComponent("hp_articles_sidebar", HpArticlesSidebar);
registerComponent("hp_articles_list", HpArticlesList);
registerComponent("node_content_daily_component", DailyPage);
registerComponent("compartmentalization", Compartmentalization);
registerComponent("views_newsflash_page", NewsflashPage);
registerComponent("images_list", imagesList);
registerComponent("rp_search_form", SearchForm);
registerComponent("rp_search_results", SearchResults);
registerComponent("section_lobby_articles", SectionLobbyArticles);
registerComponent("most_viewed_articles", Piwik);
registerComponent("rp_search_form_simple", FreeSearch);
registerComponent("hp_main_articles_ttl_aside", HPMainArticles);
registerComponent("hp_main_articles_full", HPMainArticles);
registerComponent("hp_main_articles_no_txt", HPMainArticles);
registerComponent("hp_main_articles_short_txt", HPMainArticles);
registerComponent("hp_main_articles_six", HPMainArticles);
registerComponent("custom", CustomPane);
registerComponent("favorites", Favorites);
registerComponent("menu", Menu);
registerComponent("hp_image", HpImageNodequeue);
registerComponent("views_all_content", AllContentPage);
registerComponent("leaflet_map", LeafletMap);
registerComponent('gallery', Gallery);
registerComponent('node_content_media_gallery', Gallery);
registerComponent('node_content_map', LeafletMap);
registerComponent('MapEdit', MapEdit);
