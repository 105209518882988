import React from "react";
import "date-fns";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Chip from "@material-ui/core/Chip";
import { groupBy } from "lodash";
import { nodeTypeOptions } from "../../Utils/ConvertFunctions";

import { DateRangePicker } from "react-dates";
import moment from "moment";
import "react-dates/initialize";

//Style
import "react-datepicker/dist/react-datepicker.css";
import "react-dates/lib/css/_datepicker.css";
import "./style.scss";

const resetQuery = {
  8: [],
  1: [],
  classification: [],
  files_types: [],
  node_types: [],
  reference: "",
};

const urlParamsName = {
  8: "unit",
  1: "tag",
  classification: "classification",
  node_types: "node_types",
  files_types: "files_types",
  reference: "reference",
};

const labelName = {
  8: "יחידה",
  1: "תגית",
  classification: "סיווג",
  node_types: "סוג תוכן",
  files_types: "סוגי קבצים",
  reference: "סימוכין",
};

const LIMIT = 10;

class SearchForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
      openFileTypes: false,
      groups: {
        8: [],
        1: [],
        classification: [],
        node_types: [],
        files_types: [],
      },
      selected: [],
      results: [],
      offset: 1,
      name: [],
      fileTypes: [],
      selectedFileTypes: [],
      focusedInput: null,
      focused: false,
      startDate: null,
      endDate: null,
      field_info: [],
      query: _.clone(resetQuery),
    };

    const query = gql`
      {
        taxonomyTerm(termId: ["8", "1"]) {
          name
          tid
          vid
        }
        fieldInfo(fields_name: ["field_article_type", "field_file_type"]) {
          allowed_values
        }
        aclGroups(urlApi: "getClassification", apiQueryString: "") {
          classification
        }
      }
    `;

    myApolloClient.query({ query }).then((result) => {
      const data = groupBy(result.data.taxonomyTerm, "vid");
      let groups = {};
      if (
        result.data.aclGroups[0].classification &&
        result.data.aclGroups[0].classification.length
      ) {
        const classificationJson = JSON.parse(
          result.data.aclGroups[0].classification
        ).Classification;
        groups["classification"] = [];
        Object.keys(classificationJson).forEach((item) => {
          groups.classification.push({ label: classificationJson[item], value: item})
        })
      }

      if (result.data.fieldInfo && result.data.fieldInfo.length) {
        groups["node_types"] = [];
        groups["files_types"] = [];
        Object.keys(nodeTypeOptions).forEach((item) => {;
          groups.node_types.push({ label: nodeTypeOptions[item], value: item});
        })

        const filesTypes = result.data.fieldInfo[1]["allowed_values"];
        Object.keys(filesTypes).forEach((item) => {
          groups.files_types.push({ label: filesTypes[item], value: item });
        })

      }

      Object.keys(data).length &&
        Object.keys(data).forEach((item) => {
          groups[item] = [];
          data[item].length &&
            data[item].forEach((e) => {
              //groups[item][e.tid] = e.name;
              groups[item].push({ label: e.name, value: e.tid });
            });
          groups[item].sort(function (a, b) {
            var nameA = a.label.toUpperCase(); // ignore upper and lowercase
            var nameB = b.label.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }

            // names must be equal
            return 0;
          });
        });
      this.setState({ groups });
    });

    this.handleChange = this.handleChange.bind(this);
    this.resetDates = this.resetDates.bind(this);
    this.sendToSearch = this.sendToSearch.bind(this);
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    let updatedQuery = _.cloneDeep(this.state.query);

    Object.keys(urlParamsName).forEach((stateKey) => {
      const paramName = urlParamsName[stateKey];
      const values = urlParams.getAll(paramName);

      if (values.length > 0) {
        if (paramName !== "reference") {
          updatedQuery[stateKey] = values;
        } else {
          updatedQuery["reference"] = values[0];
        }
      }
    });

    this.setState({ query: updatedQuery }, this.sendToSearch);
  }

  componentWillMount() {
    var loaded_locale = require("moment/locale/" + "he" + "");
    moment.locale("he", loaded_locale);
  }

  handleChange(value, item) {
    let updatedQuery = { ...this.state.query };
    if (!value && item !== "reference") {
      updatedQuery[item] = _.cloneDeep(resetQuery[item]);
    } else {
      updatedQuery[item] = value;
    }
    this.setState({ query: updatedQuery }, () => {
      this.updateURLParams();
    });
  }

  handleDelete(group, value) {
    this.setState(
      (prevState) => {
        // Create a new copy of the group array without the deleted value
        const updatedGroup = prevState.query[group].filter(
          (item) => item !== value
        );

        // Return a new state object with the updated group array
        return {
          query: {
            ...prevState.query,
            [group]: updatedGroup,
          },
        };
      },
      () => {
        // After state update, update URL query parameters
        this.updateURLParams(group);
      }
    );
  }

  resetFields = (field = null) => {
    // If a specific field is provided, reset only that field
    if (field && urlParamsName[field]) {
      const paramName = urlParamsName[field];
      this.cleanQueryParam(paramName); // Assuming cleanQueryParam can handle a single field

      // Handle the 'reference' field differently if it's the one being reset
      const updatedField = field !== "reference" ? [] : "";
      this.setState(
        { query: { ...this.state.query, [field]: updatedField } },
        this.sendToSearch
      );
    } else {
      // If no specific field is provided, reset all fields
      this.setState(
        {
          query: _.clone(resetQuery),
          startDate: null,
          endDate: null,
        },
        () => {
          this.cleanQueryParam();
          this.sendToSearch();
        }
      );
    }
  };

  resetDates = () => {
    this.setState(
      {
        startDate: null,
        endDate: null,
      },
      this.sendToSearch
    );
  };

  updateURLParams = (group = null) => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);

    if (group && urlParamsName[group]) {
      // Update only the specified group
      params.delete(urlParamsName[group]); // Remove current entries for this group
      this.state.query[group].forEach((value) => {
        if (value) params.append(urlParamsName[group], value); // Re-add updated values
      });
    } else {
      // Update all parameters
      Object.keys(this.state.query).forEach((key) => {
        params.delete(urlParamsName[key]); // Remove current entries for this key
        const values = this.state.query[key];
        if (Array.isArray(values)) {
          values.forEach((value) => {
            if (value) params.append(urlParamsName[key], value);
          });
        } else {
          if (values) params.append(urlParamsName[key], values);
        }
      });
    }

    // Update the URL with the modified query parameters
    url.search = params.toString();
    window.history.pushState({ path: url.href }, "", url.href);
  };

  cleanQueryParam(paramName = null) {
    const url = new URL(window.location.href);
    if (paramName) {
      // If paramName is provided, delete only that parameter
      const params = new URLSearchParams(url.search);
      params.delete(paramName);
      url.search = params.toString();
    } else {
      // If no paramName is provided, clear all query parameters
      url.search = "";
    }
    window.history.pushState({ path: url.href }, "", url.href);
  }

  sendToSearch() {
    let searchQuery = _.clone(this.state.query);
    let searchParam = [];
    let rangeDate = {};

    if (this.state.startDate) {
      const normalizedStartDate = moment(this.state.startDate).startOf("day"); // Keep it as a Moment.js object for now
      rangeDate.startDate = normalizedStartDate.unix().toString();
    }
    if (this.state.endDate) {
      const normalizedEndDate = moment(this.state.endDate).endOf("day"); // Keep it as a Moment.js object for now
      rangeDate.endDate = normalizedEndDate.unix().toString();
    }

    Object.keys(searchQuery).map((item) => {
      if (item === "reference")
        searchQuery[item].length &&
          searchParam.push({ tid: item, free_query: searchQuery[item] });
      else
        searchQuery[item].length &&
          searchParam.push({ tid: item, query: searchQuery[item] });
    });
    this.args = { offset: this.state.offset, limit: LIMIT };

    if (searchParam.length) {
      this.args.fieldsSearch = searchParam;
      Api.searchParam = this.args.fieldsSearch;
    }

    if (Object.keys(rangeDate).length) {
      this.args.range = rangeDate;
      Api.searchRang.range = this.args.range;
    }

    Api.searchObj = this.args;
    Api.searchQuery();
  }

  ResetButton = ({ handleReset }) => (
    <div className="reset-button-div">
      <div className="reset-button-clean" onClick={handleReset}>
        נקה
      </div>
    </div>
  );

  getLabel(items, value) {
    const item = items.find((item) => item.value === value);
    return item ? item.label : "";
  }

  render() {
    return (
      <div>
        <div id="reset-all" className="reset" onClick={this.resetFields}>
          נקה הכל
        </div>
        {Object.keys(this.state.groups).map((item) => (
          <div key={item}>
            <FormControl key={item} fullWidth={true}>
              <InputLabel
                className="select-multiple-label"
                htmlFor="select-multiple-checkbox"
                onClick={() => {
                  this.setState({ menuOpen: labelName[item] });
                }}
              >
                {labelName[item]}
              </InputLabel>
              {this.state.query[item] && (
                <Select
                  multiple
                  disabled
                  className="select-multiple-select"
                  value={this.state.query[item]}
                  onChange={(e) => {
                    this.handleChange(e.target.value, item);
                  }}
                  open={this.state.menuOpen == labelName[item]}
                  onClose={() => {
                    this.setState({ menuOpen: false });
                  }}
                  input={<Input id="select-multiple-checkbox" />}
                  renderValue={(selected) => (
                    <div className="chips">
                      {selected.map((value) => (
                        <Chip
                          key={value}
                          label={this.getLabel(this.state.groups[item],value)}
                          onDelete={() => {
                            this.handleDelete(item, value);
                          }}
                          className="selected-item"
                        />
                      ))}
                    </div>
                  )}
                >
                  {this.state.groups[item]
                  .map((obj) => (
                    <MenuItem key={obj.label} value={obj.value} className={"groups-item"}>
                      <Checkbox
                        checked={this.state.query[item].indexOf(obj.value) > -1}
                        classes={{ root: "checkbox-item" }}
                      />
                      {obj.label}
                    </MenuItem>
                  ))}
                </Select>
              )}
              {this.state.query[item] && this.state.query[item].length > 0 && (
                <this.ResetButton handleReset={() => this.resetFields(item)} />
              )}
            </FormControl>
            <hr />
          </div>
        ))}

        <TextField
          className="textField-reference"
          placeholder="סימוכין"
          margin="normal"
          variant="outlined"
          value={this.state.query["reference"]}
          onChange={(e) => {
            this.handleChange(e.target.value, "reference");
          }}
        />
        {this.state.query["reference"] && (
          <this.ResetButton handleReset={() => this.resetFields("reference")} />
        )}
        <hr />

        <div className="post-date-label">
          תאריך פרסום{" "}
          {(this.state.startDate || this.state.endDate) && (
            <div
              id="reset-dates"
              className="reset-date"
              onClick={this.resetDates}
            >
              נקה
            </div>
          )}
        </div>
        <div id="startDate"></div>
        <div id="endDate"></div>
        <div className="date-ltr">
          <DateRangePicker
            startDate={this.state.startDate} // momentPropTypes.momentObj or null,
            startDateId="startDate" // PropTypes.string.isRequired,
            endDate={this.state.endDate} // momentPropTypes.momentObj or null,
            endDateId="endDate" // PropTypes.string.isRequired,
            onDatesChange={({ startDate, endDate }) =>
              this.setState({ startDate, endDate })
            } // PropTypes.func.isRequired,
            focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
            onFocusChange={(focusedInput) => this.setState({ focusedInput })} // PropTypes.func.isRequired,
            minimumNights={0}
            navNext={
              <div className="arrow">
                <span className="right"></span>
              </div>
            }
            navPrev={
              <div className="arrow">
                <span className="left"></span>
              </div>
            }
            // customInputIcon={(<span>-</span>)}
            startDatePlaceholderText="D.M.Y"
            endDatePlaceholderText="D.M.Y"
            displayFormat={"D.M.YY"}
            orientation={"horizontal"}
            numberOfMonths={1}
            isRTL={false}
            hideKeyboardShortcutsPanel={true}
            isOutsideRange={(day) => {
              return day.startOf("day").isAfter(moment().startOf("day"));
            }}
          />
        </div>
        <div className="send-to-search" onClick={this.sendToSearch}>
          חפש
        </div>
      </div>
    );
  }
}

SearchForm.propTypes = {};

export default SearchForm;
