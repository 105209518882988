import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import gql from "graphql-tag";
import { getImageSrc } from '../../Utils/ConvertFunctions';
// import Swiffy Slider JS
import 'swiffy-slider/dist/js/swiffy-slider.js'
// import Swiffy Slider CSS
import "swiffy-slider/dist/css/swiffy-slider.min.css"
//Style
import './style.scss';

class Gallery extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      galleryData: {},
      showTitle: false,
      nid: props.nid ? props.nid : false
    }
    if (!props.nid) { return }
    const query = gql`
    {
      entity(ids:[{id:${props.nid}, style:"full"}]){
        title
        id
        body
        gallery {
          images {
            title
            image_uri
            link_to
          }
          videos {
            title
            video_uri
            link_to
          }
          iframes {
            title
            video_uri
            link_to
          }
          show_title
        }

      }
    }
    `;


    myApolloClient.query({ query }).then((result) => {
      this.setState({galleryData: result.data?.entity[0]})
      if (props.hasOwnProperty('show_titles')) {
        this.setState({ showTitle: (parseInt(props?.show_titles) === 1) ? true : false })
      }
      else {
        this.setState({ showTitle: result.data?.entity[0]?.gallery.show_title})
      }

    });


    this.sliderContainerRef = createRef();
    this.sliderRef = createRef();

    //for video playing
    window.sessionStorage.setItem('vp','none');

  }

  slideChange (e) {
    const videoId = window.sessionStorage.getItem('vp');
    if (videoId !== 'none') {
      const video = document.getElementById(videoId);
      video?.pause();
      window.sessionStorage.setItem('vp', 'none');
    }
  }

  componentDidMount() {
    if (!this.state.nid){return}
    this.gallery = this.state.galleryData?.gallery || null;
    window.swiffyslider.init();
    window.swiffyslider.onSlideEnd(this?.sliderRef?.current, this?.slideChange);
    this.iframeHeight = this?.sliderRef?.current?.offsetHeight;
  }

  renderImageCard(item) {
    return (
      <div className='slide'>
        <div className='image'>
          <img src={decodeURI(item.image_uri)} />
        </div>
        {this.state.showTitle && <div className='title'>{item.title}</div>}
      </div>
    )
  }

  renderVideoCard(item) {
    return (
      <div className='slide'>
        <div className='image video'>
          <video controls width="auto" ref={item.ref} key={item.id} id={item.id}>
            <source src={item.video_uri} type="video/mp4" />
          </video>
          {this.state.showTitle && <div className='title'>{item.title}</div>}
        </div>
      </div>
    )
  }

  renderIframeCard(item) {
    return (
      <div className='slide'>
        <div className='video iframe'>
          <iframe src={item.video_uri} width="100%" height={this.iframeHeight}></iframe>
        </div>
        {this.state.showTitle && <div className='title'>{item.title}</div>}
      </div>
    )
  }

  render() {
    if (!this.state.nid) { return null}
    const gallery = this.state.galleryData?.gallery
    if (gallery) {
      this.iframeHeight = 'auto';
      for (let i = 0; i < gallery?.videos?.length; i++) {
        const id = Math.random().toString(36).substring(2, 15) +
          Math.abs(Date.now()).toString(36).substring(2, 15);
        gallery.videos[i].id = id;
        gallery.videos[i].ref = createRef();
      }
      //event listener for video playing
      setTimeout(function () {
        for (let i = 0; i < gallery.videos.length; i++) {
          gallery.videos[i].ref?.current?.addEventListener('play', (event) => {
            window.sessionStorage.setItem('vp', gallery.videos[i].id);
          });
        }
      }, 1000);

    }
    let conter = 0;
    const images = !gallery ? [] : gallery.images.map((item) => {
      const card = this.renderImageCard(item);
      return (
        item.link_to ? <a href={item.link_to} target='_blank' title={Drupal.t('read more')}>{card}</a> : card
      );
    });

    const videos = !gallery ? [] : gallery.videos.map((item) => {
      const card = this.renderVideoCard(item);

      return (
        item.link_to ? <a href={item.link_to} target='_blank' title={Drupal.t('read more')}>{card}</a> : card
      );
    });

    const iframes = !gallery ? [] : gallery.iframes.map((item, index) => {
      const card = this.renderIframeCard(item);
      return (
        item.link_to ? <a href={item.link_to} target='_blank' title={Drupal.t('read more')}>{card}</a> : card
      );
    });

    const allCards = [...images, ...videos, ...iframes];

    const slides = allCards.map((card, index) => {
      return (
        <li key={index}>{card}</li>
      )
    });

    const buttons = allCards.map((item, index) => {
      return (
        <button key={index} className={index === 0 ? 'active' : ''}></button>
      )
    });

    const sliderItemRatio = (this?.props?.panel === 'main_left') ? 'slider-item-ratio-contain' : '';

    return (
      <div className="gallery" id={this.props.panel}>
        <div className="pane-content">
          <div className="pane-title">{this.gTitle}</div>
          <div className="pane-body description" dangerouslySetInnerHTML={{ __html: this.body }} />
          <div ref={this.sliderRef} className={`swiffy-slider slider-nav-square slider-nav-dark ${sliderItemRatio}`} dir='ltr'>
            <ul className="slider-container" id="sliderContainer" ref={this.sliderContainerRef}>
              {slides}
            </ul>

            <button type="button" className="slider-nav"></button>
            <button type="button" className="slider-nav slider-nav-next"></button>



            <div className="slider-indicators" dir='rtl'>
              {buttons}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Gallery.propTypes = {};
export default Gallery;
