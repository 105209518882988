import React from 'react';
import gql from "graphql-tag";
import MetaTags from 'react-meta-tags';

//Componnets
import InnerPageHeader from '../InnerPageHeader/index';
import InnerPageFooter from '../InnerPageFooter/index';

// import AssignmentIcon from '@material-ui/icons/Assignment';
import { createMarkup, goTo403 } from '../../Utils/ConvertFunctions';
import { setDataInMatomo, setTitleAndUrlInMatomo } from '../../Utils/matomo'

//Style
import './style.scss';

//Vars
let videoStartTime = false;
let videoWatchedTime = 0;
class DailyPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      playVideo: false,
      activeArea: '-1',
      data: -1,
      comment_count: ''
    };

    const query = gql`
    {
      entity(ids:[{id:${props.nid},style:"${props.build_mode}"}]){
        main_title_inner_page
        id
        writer_name
        confirmed
        tested
        compatible_with
        reference
        reading_time
        created
        confirmed
        image_src
        video_url
        date_update
        image_video
        comment_status
        classification
        is_blue
        vote_count
        triangle
        triangleName
        reference_links{
          title
          url
        }
        comments{
          cid
          pid
          name
          subject
          body
          created
          displayName
        }
        image_size
        tags {
          id
          name
        }
        area{
          name
          description
          image
          paragraph{
            paragraphTitle
            paragraphDescription
          }
        }
        unit{
          name
          icon
        }
        attached_files{
          name
          url
          type
          size
        }
        article_reference {
          id
          main_title_homepage
          image_src
          path
          article_type
          image_video
        }
        publishProcedureAllow
      }
    }
    `;

    myApolloClient.query({ query }).then(result => {
      let comment_count = result.data.entity.length && result.data.entity[0] && result.data.entity[0].comments ? result.data.entity[0].comments.length : 0;
      this.setState({ data: result.data.entity[0], comment_count });

      // this.state.data.classification && setDataInMatomo(['trackEvent', 'סיווג', this.state.data.classification]);
    });
  }

  setActiveArea(key) {
    this.state.activeArea == key ? this.setState({ activeArea: -1 }) : this.setState({ activeArea: key });
    this.imagesRender(key);
  }

  imagesRender(key) {
    let body = document.querySelectorAll('.daily-page .area_description')[key] || document.querySelectorAll('.daily-page .area_paragraph')[key];
    if (body) {
      let images = body.querySelectorAll('img');
      for (let i = 0; i < images.length; i++) {
        if (images[i].getAttribute('longdesc') && !images[i].getAttribute('react-render')) {
          let el = images[i];
          let wrapper = document.createElement('div');
          wrapper.className = 'wrap-img';
          el.parentNode.insertBefore(wrapper, el);
          wrapper.appendChild(el);
          let desc = document.createElement('div');
          desc.className = 'description';
          desc.innerHTML = images[i].getAttribute('longdesc');
          images[i].setAttribute('react-render', true)
          images[i].parentNode.insertBefore(desc, images[i].nextSibling)
        }
      }
    }
  }

  videoOnPlay(event) {
    videoStartTime = event.timeStamp;
    this.setState({ playVideo: true })
  }

  videoOnPause(event) {
    const time = Math.floor(event.timeStamp - videoStartTime);
    videoStartTime = 0;
    videoWatchedTime += time;
    this.setState({ playVideo: false });
  }

  videoAddEvent(body) {
    let allVideo = body.querySelectorAll('video');
    Object.values(allVideo).map((video, index) => {
      video.addEventListener('play', (event) => this.videoOnPlay(event))
      video.addEventListener('pause', (event) => this.videoOnPause(event))
    })
  }

  componentDidMount() {
    window.addEventListener('beforeunload', (event) => this.handleTabClose(event));

    setTimeout(function () {
      let body = document.querySelector('.node-type-daily-component .content-body')
      if (body) {
        this.videoAddEvent(body);
      }
    }
      .bind(this),
      1000);
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', (event) => this.handleTabClose(event));
  }

  handleTabClose(event) {
    event.preventDefault();

    // Set whatched video time.
    if (videoWatchedTime || videoStartTime) {
      if (videoStartTime !== 0) {
        this.videoOnPause(event);
      }
      const minutes = `0${Math.floor(videoWatchedTime / 1000 / 60)}`.slice(-2);
      const sec = `0${Math.floor(videoWatchedTime / 1000 % 60)}`.slice(-2);
      setDataInMatomo(['trackEvent', 'זמן צפייה בוידאו', this.state.data.main_title_inner_page, `${minutes}:${sec}`]);
    }

    return;
  };

  render() {
    return (
      <div className="daily-page">
        { this.state.data != -1 && (this.state.data && this.state.data.id ?
          <div>
            < MetaTags >
              <title>{ this.state.data.main_title_inner_page }</title>
              <meta id="og-title" property="og:title" content={ this.state.data.main_title_inner_page } />
            </MetaTags >
            {
              setTitleAndUrlInMatomo(this.state.data.main_title_inner_page)
            }
            <InnerPageHeader { ...this.state.data } comment_count={ this.state.comment_count } />
            <div className="content-body">
              <div className="areas">
                { !!this.state.data.area && !!this.state.data.area.length && this.state.data.area.map((item, key) => (
                  <div
                    key={ key }
                    className={ `area ${this.state.activeArea == key ? 'active' : ''}` }>
                    <div className="area_top" onClick={ () => { this.setActiveArea(key) } }>
                      <div className="area_image">
                        <img src={ item.image } />
                      </div>
                      <div className="area_name">
                        <span >{ item.name } </span>
                      </div>
                    </div>
                    { !!item.description &&
                      <div className="area_description" style={ { marginRight: '-' + (key % 3) * 100 + '%' } }>
                        <span>
                          <div dangerouslySetInnerHTML={ createMarkup(item.description) }></div>
                        </span>
                      </div>
                    }
                    { !!item.paragraph && !!item.paragraph.length &&
                      <div className="area_paragraph" style={ { marginRight: '-' + (key % 3) * 100 + '%' } }>
                        { item.paragraph.map((paragraph, key) => (
                          <span>
                            <h3 dangerouslySetInnerHTML={ createMarkup(paragraph.paragraphTitle) }></h3>
                            <div dangerouslySetInnerHTML={ createMarkup(paragraph.paragraphDescription) }></div>
                          </span>
                        )) }
                      </div>
                    }
                  </div>
                )) }
              </div>
            </div>
            <InnerPageFooter { ...this.state.data } onComment={ (comment_count) => { this.setState({ comment_count }); } } />
          </div> :
          goTo403(this.state.data)) }
      </div>
    );
  }
}

DailyPage.propTypes = {

};

export default DailyPage;
